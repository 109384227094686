import styled from 'styled-components';
import { IconArrowLeft } from '../Icons';
import { Heading1 } from '../Heading/Heading';
import { windowMaxWidth } from './breakpoints';

// helper function that returns the margin for the action buttons wrapper
const getActionButtonsWrapperMargin = (showSidebar, isSplitScreen) => {
  const CONTENT_MAX_SIZE = 1840;
  const SIDEBAR_WIDTH = 600;
  const DEFAULT_MARGIN = 20;

  const windowInnerWidth = window.innerWidth;

  if (showSidebar) {
    if (isSplitScreen) {
      return '0px';
    }
    // window is max the size of the CONTENT_MAX_SIZE
    if (windowInnerWidth <= CONTENT_MAX_SIZE) {
      return `${SIDEBAR_WIDTH}px`;
    }

    // window is larger than the CONTENT_MAX_SIZE
    const margin = Math.ceil(SIDEBAR_WIDTH - (windowInnerWidth - CONTENT_MAX_SIZE) / 2);
    if (margin > 0) {
      return `${margin}px`;
    }
  }

  return `${DEFAULT_MARGIN}px`;
};

const MainWrapper = styled.div`
  margin: 0 20px 20px 20px;

  @media ${windowMaxWidth.splitScreen} {
    overflow: overlay;
    width: calc(100vw - 20px);
    margin: 0 10px 20px 10px;
  }
`;

const NavigationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100px;
  justify-content: space-between;

  @media ${windowMaxWidth.mobile} {
    height: auto;
    padding: 15px 0 5px;
    gap: 10px;
  }
`;

const IconBackWrapper = styled.div`
  margin-right: 20px;

  @media ${windowMaxWidth.mobile} {
    button {
      margin-right: 0px;
      width: 30px !important;
      height: 30px !important;
      margin-left: 10px;
    }
  }
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: 20px;

  @media ${windowMaxWidth.mobile} {
    margin-left: 0;
  }
`;

const HeaderTitleWrapperMobile = styled.div`
  display: none;

  @media ${windowMaxWidth.mobile} {
    display: flex;
    flex-direction: row;
    flex: 1 0 100%;
    padding: 10px 20px;
    border: 1px solid var(--greyscale-dark-white);
    border-radius: var(--border-radius-regular);
    background-color: var(--greyscale-white);
    align-items: center;
  }
`;

const TitleHeaderMobile = styled.span`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-regular);
`;

const TitleSubHeaderMobile = styled.span`
  color: var(--greyscale-shade);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;

  @media ${windowMaxWidth.mobile} {
    display: none;
  }
`;

const HeaderTitleIcon = styled.div`
  height: 50px;
  margin-right: 6px;
`;

const HeaderTitleIconMobile = styled.div`
  height: 40px;
  margin-right: 6px;
  width: 40px;

  svg {
    height: 40px;
    width: 40px;
  }
`;

const TitleHeadersWrapper = styled.div``;

const TitleHeadersWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleHeader = styled(Heading1)`
  font-size: var(--font-size-heading-3);
  line-height: var(--font-line-height-medium);
  margin-bottom: 4px;
  margin-top: 0;
`;

const TitleHeaderPlaceholder = styled(Heading1)`
  font-size: var(--font-size-heading-3);
  line-height: var(--font-line-height-medium);
  margin-bottom: 4px;
  margin-top: 0;
`;

const TitleSubHeader = styled.span`
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${({ showSidebar, isSplitScreen }) => getActionButtonsWrapperMargin(showSidebar, isSplitScreen)};
  transition: all 0.3s;

  @media ${windowMaxWidth.splitScreen} {
    margin-right: 20px;
  }

  @media ${windowMaxWidth.mobile} {
    margin-right: 10px;
  }
`;

const ActionExtrasButton = styled.button`
  align-items: center;
  background: transparent;
  border: 2px solid var(--primary-oqio-blue);
  border-radius: var(--border-radius-small);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-heading-3);
  height: 40px;
  justify-content: space-around;
  width: 40px;

  svg * {
    fill: var(--primary-oqio-blue);
  }

  &:hover {
    background-color: var(--primary-oqio-blue-hover-transparent);
  }

  @media ${windowMaxWidth.splitScreen} {
    margin: 0 2px 0 2px;
  }

  @media (${windowMaxWidth.mobile}) {
    display: ${(props) => (props.$hideOnMobile ? 'none' : 'flex')};
  }
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (${windowMaxWidth.splitScreen}) {
    flex-direction: column;
  }
`;

const ContentLeftWrapper = styled.div`
  background-color: var(--greyscale-white);
  border: 1px solid var(--greyscale-dark-white);
  border-radius: var(--border-radius-regular);
  height: calc(100vh - 180px);
  max-width: 1250px;
  min-width: 600px;
  padding-top: 10px;
  width: calc(100vw - 590px);

  @media (${windowMaxWidth.splitScreen}) {
    min-width: calc(100vw - 20px);
    height: auto;
    min-height: calc(100vh - 180px);
  }

  @media (${windowMaxWidth.mobile}) {
    min-height: calc(100dvh - 130px);
  }
`;

const ContentRightWrapper = styled.div`
  border-radius: var(--border-radius-small);
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 180px);
  margin-left: 20px;
  max-width: 530px;

  @media (${windowMaxWidth.splitScreen}) {
    height: auto;
    margin-left: 0;
    margin: 20px 0;
    max-width: unset;
    width: calc(100vw - 20px);
  }

  @media (${windowMaxWidth.mobile}) {
    display: none;
  }
`;

const MapWrapper = styled.div`
  border: 1px solid var(--greyscale-white);
  border-radius: var(--border-radius-small);
  height: calc(50% - 10px);
  max-height: 350px;
  width: 530px;

  .MicrosoftMap {
    border-radius: var(--border-radius-regular);

    div {
      border-radius: var(--border-radius-small);
    }
  }

  @media (${windowMaxWidth.splitScreen}) {
    width: calc(100%);
    height: 100vw;
  }

  @media (${windowMaxWidth.mobile}) {
    display: none;
  }
`;

const CarouselWrapper = styled.div`
  -ms-user-select: none;
  -webkit-user-select: none;
  background-color: var(--greyscale-white);
  border-radius: var(--border-radius-small);
  height: calc(50% - 10px);
  max-height: 350px;
  overflow: hidden;
  position: relative; /* Safari */
  user-select: none; /* IE 10 and IE 11 */
  width: 530px;

  > div.ant-carousel {
    height: 100%;
    max-height: 100%;
  }

  @media (${windowMaxWidth.splitScreen}) {
    width: 100%;
  }

  @media (${windowMaxWidth.mobile}) {
    display: none;
  }
`;

const DetailsLayout = {
  MainWrapper,
  NavigationWrapper,
  TitleWrapper,
  HeaderTitleWrapperMobile,
  TitleHeaderMobile,
  TitleSubHeaderMobile,
  HeaderTitleWrapper,
  HeaderTitleIcon,
  HeaderTitleIconMobile,
  TitleHeadersWrapper,
  TitleHeadersWrapperMobile,
  TitleHeader,
  TitleHeaderPlaceholder,
  TitleSubHeader,
  ActionButtonsWrapper,
  ActionExtrasButton,
  IconBackWrapper,
  IconBack: IconArrowLeft,
  MainContentWrapper,
  ContentLeftWrapper,
  ContentRightWrapper,
  MapWrapper,
  CarouselWrapper
};

export default DetailsLayout;
