import { combineReducers } from 'redux';
import Auth from './auth/reducer';
import Authorizations from './authorization/reducer';
import Buildings from './buildings/reducer';
import BuildingToPolicies from './BuildingToPolicies/reducer';
import Claims from './claims/reducer';
import ClaimAuthorizations from './claimAuthorizations/reducer';
import Config from './config/reducer';
import DocumentFetching from './documentFetching/reducer';
import FileUpload from './fileUpload/reducer';
import Filters from './filters/reducer';
import HousingStocks from './housingStocks/reducer';
import InsuredRisks from './insuredRisks/reducer';
import Kpis from './kpis/reducer';
import Layout from './layout/reducer';
import Organizations from './organizations/reducer';
import Policies from './policies/reducer';
import Settlements from './settlements/reducer';
import Sidebar from './sidebar/reducer';
import Users from './users/reducer';
import WorklistClaims from './worklistClaims/reducer';
import FileContainers from './fileContainers/reducer';
import Import from './import/reducer';

export default combineReducers({
  Auth,
  Authorizations,
  Buildings,
  BuildingToPolicies,
  Claims,
  ClaimAuthorizations,
  Config,
  DocumentFetching,
  FileUpload,
  Filters,
  HousingStocks,
  InsuredRisks,
  Kpis,
  Layout,
  Organizations,
  Policies,
  Settlements,
  Sidebar,
  Users,
  Import,
  WorklistClaims,
  FileContainers
});
