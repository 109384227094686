import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { parse as parseContentDispositionHeader } from 'content-disposition-header';
import { CLAIM_DEFAULT_INSURED_RISK_TYPE } from '../consts/consts';
import { INSURED_RISK_TYPES_DICTIONARY } from '../consts/dictionaries';
import { deviceSizeNumber } from '../components/ui/Layout/breakpoints';

const EmptyValueWrapper = styled.span`
  color: var(--greyscale-concrete);
  display: flex;
`;

export const currencyValueFormat = (value, currencyPosition = 'after') => {
  if (value === null || value === undefined || isNaN(value)) {
    return null;
  }
  const minimumFractionDigits = value % 1 !== 0 ? 2 : 0;
  const localization = currencyPosition === 'after' ? 'de-DE' : 'pt-BR';
  const returnValue = new Intl.NumberFormat(localization, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits
  }).format(value);

  return returnValue;
};

export const decimalValueFormat = (value) => {
  if (value === null || value === undefined || isNaN(value)) {
    return null;
  }
  return new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  }).format(value);
};

export const inputNumberFormatterWithoutCurrency = (value) => {
  const result = value
    ? new Intl.NumberFormat('de-DE', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }).format(value)
    : '';

  return result.toString();
};

export const inputSquaremeterFormatter = (value) => {
  const parsedValue = value ? value.toString().replace('.', ',') : '';
  const parseDecimalPlaces =
    parsedValue.indexOf(',') >= 0
      ? parsedValue.substr(0, parsedValue.indexOf(',')) + parsedValue.substr(parsedValue.indexOf(','), 3)
      : parsedValue;

  return `${parseDecimalPlaces}`;
};

export const squaremeterParser = (value) => {
  const parsedValue =
    value && value.indexOf(',') >= 0
      ? value.substr(0, value.indexOf(',')) + value.substr(value.indexOf(','), 3)
      : value;

  return parsedValue
    ? parsedValue
        .replace(/[^0-9,.]+/g, '')
        .replace(/(,.*?),(.*,)?/, '$1')
        .replace(/,+/g, '.')
    : '';
};

export const moneyParser = (value) => {
  // If the vale has decimal places, we keep only the first two
  const parsedValue =
    value && value.indexOf(',') >= 0
      ? value.substr(0, value.indexOf(',')) + value.substr(value.indexOf(','), 3)
      : value;

  const result = parsedValue
    ? parsedValue
        // Replaces "€ ", "€", or a series of dots with an empty string
        .replace(/€\s?|(\.*)/g, '')
        // Replace any character that is NOT a number or a comma with an empty string
        .replace(/[^0-9,]+/g, '')
        //? Old code (seems to remove multiple commas), not sure if it is needed
        .replace(/(,.*?),(.*,)?/, '$1')
        // Replaces comma with dot (as floats are represented with dots)
        .replace(/,+/g, '.')
    : '';

  return result;
};

export const removeEmpty = (item) => {
  const localItem = item;
  if (typeof localItem === 'object') {
    Object.keys(localItem).forEach((key) => {
      if (localItem[key] && typeof localItem[key] === 'object') removeEmpty(localItem[key]);
      else if (localItem[key] === undefined) delete localItem[key];
    });
  }
  return localItem;
};

/**
 * Searches for a `meta_data` description by property name within a given object.
 * If the property is found, it returns the value of the specified item key. If not, it returns the default value.
 *
 * @param {Object} object - The object containing the metadata.
 * @param {string} propertyName - The property name to search for within the metadata.
 * @param {*} defaultValue - The default value to return if the property is not found.
 * @param {string} itemKey - The key of the item within the metadata to retrieve the value from.
 * @returns {*} The found value associated with the itemKey or the defaultValue if not found.
 */
export const findMetaDataDescriptionByProperty = (object, propertyName, defaultValue, itemKey) => {
  const foundValue = object?.meta_data?.find((item) => item.rel === propertyName)?.[itemKey];
  return foundValue ?? defaultValue;
};

// returns the insured risk type of the insured risk
export const findInsuredRiskTypeByInsuredRiskId = (insuredRisks, insuredRiskId) => {
  const insuredRiskType =
    insuredRisks.find((insuredRisk) => insuredRisk.id === insuredRiskId)?.type || CLAIM_DEFAULT_INSURED_RISK_TYPE;
  return insuredRiskType;
};

// returns building details from claim
export const getBuildingDetails = (claim) => {
  const buildingStreet = findMetaDataDescriptionByProperty(claim, 'building_street', null, 'description');
  const buildingZipcode = findMetaDataDescriptionByProperty(claim, 'building_zipcode', null, 'description');
  const buildingTown = findMetaDataDescriptionByProperty(claim, 'building_town', null, 'description');
  const buildingLatitude = findMetaDataDescriptionByProperty(claim, 'building_lat', null, 'value_double');
  const buildingLongitude = findMetaDataDescriptionByProperty(claim, 'building_lon', null, 'value_double');
  const buildingHousingStock = findMetaDataDescriptionByProperty(claim, 'housing_stock', null, 'description');
  const buildingText = claim?.building_text || null;
  const buildingId = claim?.building_id || null;

  return {
    buildingStreet,
    buildingZipcode,
    buildingTown,
    buildingLatitude,
    buildingLongitude,
    buildingHousingStock,
    buildingText,
    buildingId
  };
};

// returns a boolean if the claim has coordinates
export const checkIfClaimHasCoordinates = (claim) => {
  const latitude = findMetaDataDescriptionByProperty(claim, 'building_lat', null, 'value_double');
  const longitude = findMetaDataDescriptionByProperty(claim, 'building_lon', null, 'value_double');
  return Boolean(latitude && longitude);
};

export const getSortParameterValue = (columnKey, sortOrder) => {
  if (!columnKey || !sortOrder) {
    return undefined;
  }
  const orderCharacter = sortOrder === 'ascend' ? '+' : '-';
  switch (columnKey) {
    case 'housingStockName':
      return `${orderCharacter}housing_stock`;
    case 'street':
      return `${orderCharacter}street`;
    case 'zipCode':
      return `${orderCharacter}zipcode`;
    case 'town':
      return `${orderCharacter}town`;
    case 'insuranceOrgId':
      return `${orderCharacter}insurance_org`;
    case 'insurancePolicyNumber':
      return `${orderCharacter}insurance_policy_number`;
    case 'addendum':
      return `${orderCharacter}addendum`;
    case 'startDate':
      return `${orderCharacter}start_date`;
    case 'endDate':
      return `${orderCharacter}end_date`;
    case 'customField':
      return `${orderCharacter}custom_field`;
    case 'companyCode':
      return `${orderCharacter}company_code`;
    case 'economicUnit':
      return `${orderCharacter}economic_unit`;
    case 'externalId':
      return `${orderCharacter}external_id`;
    default:
      return undefined;
  }
};

export const parseChartYAxisValue = (value) => `${decimalValueFormat(value)}€`;

const createActiveFilterListObject = (activeFilters) => {
  const activeFiltersObject = queryString.parse(activeFilters);
  const REMOVE_FROM_FILTERS = ['page', 'search', 'sort', 'selectedMarker'];
  const activeFilterListFilters = Object.keys(activeFiltersObject).filter((key) => !REMOVE_FROM_FILTERS.includes(key));
  return activeFilterListFilters;
};

export const checkForActiveFilters = (activeFilters) => {
  const activeFilterListFilters = createActiveFilterListObject(activeFilters);
  const hasActiveFilters = Object.keys(activeFilterListFilters).length > 0;
  return hasActiveFilters;
};

export const getTableColumnsSortOrder = (sortingParameter, sortParameter) => {
  if (!sortingParameter) return null;

  const [sortParameterDirection, sortParameterWithoutDirection] = [
    sortingParameter.charAt(0),
    sortingParameter.slice(1)
  ];

  return sortParameterWithoutDirection === sortParameter
    ? sortParameterDirection === '+'
      ? 'ascend'
      : 'descend'
    : null;
};

// ! Please keep into mind that it doesn't work for all cases. Specifically for the `insured-risk-to-policy` object type.
export const getTitleFromObject = (object, objectType) => {
  switch (objectType) {
    case 'building':
      return `${object.street}, ${object.zipcode}, ${object.town}`;
    case 'policy':
      return `${INSURED_RISK_TYPES_DICTIONARY[object.type]}, ${object.insurance_policy_number} ${
        object.addendum ? `, ${object.addendum}` : ''
      }`;
    default:
      return object.title;
  }
};

// returns an array of translated insured risk types, sorted by the order of the dictionary
export const getTranslatedInsuredRiskTypes = (insuredRisksIds, insuredRisks) => {
  if (!insuredRisksIds || !insuredRisks) {
    return;
  }

  const filteredRisks = insuredRisks.filter((risk) => insuredRisksIds.includes(risk.id));

  const translatedTypes = filteredRisks
    .map((risk) => INSURED_RISK_TYPES_DICTIONARY[risk.type])
    .filter((type) => type !== undefined);

  const uniqueTranslatedTypes = [...new Set(translatedTypes)];
  const sortOrder = Object.values(INSURED_RISK_TYPES_DICTIONARY);
  uniqueTranslatedTypes.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));

  return uniqueTranslatedTypes;
};

// highlightMatches is a helper function that will highlight the search value in the options.
// also it will add a tooltip to the options that are known objects in the database.
export const highlightMatches = (text, searchValue, className = '', suffix = null) => {
  if (!searchValue.trim())
    return (
      <span className={className}>
        {text}
        {suffix}
      </span>
    );

  const regex = new RegExp(`(${searchValue})`, 'gi');
  const parts = text.split(regex);

  const highlightedText = parts.map((part, index) => {
    const key =
      part.toLowerCase() === searchValue.toLowerCase() ? `highlight-${index}-${part}` : `text-${index}-${part}`;

    return (
      <span key={key} className={part.toLowerCase() === searchValue.toLowerCase() ? 'highlight' : 'non-highlight'}>
        {part}
      </span>
    );
  });

  return (
    <span className={className}>
      {highlightedText}
      {suffix}
    </span>
  );
};

export const getFileNameFromApiResponseHeader = (responseHeader, fileExtension) => {
  const contentDispositionHeader = responseHeader['content-disposition'];
  let filename = parseContentDispositionHeader(contentDispositionHeader).parameters.filename;
  const defaultFilename = `default-filename${fileExtension}`;
  return filename || defaultFilename;
};

export const EmptyValue = () => <EmptyValueWrapper>—</EmptyValueWrapper>;

// scroll function for list pages
export const scrollIntoView = (listRef, lastVisitedId) => {
  if (!lastVisitedId || !listRef) {
    return;
  }

  const listItems = listRef?.current?.querySelector('.ant-list-items');
  const lastVisitedItem = listRef?.current?.querySelector('.last-visited-item');

  // check if the device is mobile
  const isMobile = window.innerWidth <= deviceSizeNumber.mobile;

  if (lastVisitedItem) {
    lastVisitedItem.scrollIntoView({ block: 'center' });
    return;
  }

  if (listItems && !isMobile) {
    listItems.scrollTo({ top: 0 });
  }
};

// check if the device is mobile
export const isMobile = window.innerWidth <= deviceSizeNumber.mobile;
