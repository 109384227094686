import styled from 'styled-components';
import { Heading2 } from '..';

// I used color codes instead of variables because for IE the variables are not working
const ErrorPageWrapper = styled.div`
  align-items: center;
  background-color: #f5f6fa;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

// I used color codes instead of variables because for IE the variables are not working
const ErrorPageMessageWrapper = styled.div`
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;
  padding: 20px;
  width: 400px;
`;

const ErrorPageIconWrapper = styled.span``;

const ErrorPageHeadline = styled(Heading2)`
  margin-bottom: 15px;
  text-align: center;
`;

const ErrorPageMessage = styled.div`
  font-size: 16px;
  margin-bottom: 40px;
  overflow-wrap: break-word;
  text-align: center;
  width: 100%;
`;

const ErrorPageLink = styled.a``;

const ErrorPageLayout = {
  ErrorPageWrapper,
  ErrorPageMessageWrapper,
  ErrorPageIconWrapper,
  ErrorPageHeadline,
  ErrorPageMessage,
  ErrorPageLink
};

export default ErrorPageLayout;
