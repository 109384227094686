import styled from 'styled-components';
import AntImage from 'antd/es/image';

const Image = styled(AntImage)`
  &.details-page-carousel-image {
    max-height: 100% !important;
    max-width: 100% !important;
    object-fit: cover;

    & + .ant-image-mask:hover {
      opacity: 0;
    }

    &.ant-image-img {
      width: unset !important;
    }
  }

  &.details-page-files-image {
    border-radius: var(--border-radius-small);
    height: 100%;
    object-fit: cover;
    width: 100%;

    & + .ant-image-mask:hover {
      opacity: 0;
    }

    &.no-placeholder {
      background-image: unset;
    }
  }
  &.worklist-claim-message-attachment {
    display: none !important;
  }
`;

export default Image;
