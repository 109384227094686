import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { sentryCaptureValidationException } from '../../config/sentry';
import { Form, Modal, InputPassword, Button } from '../ui';
import renderNotification from '../../helpers/notifications/renderNotification';
import PasswordRules from '../../containers/Login/PasswordRules';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import { DEFAULT_RULES } from '../../consts/formRules';

const TopbarModalFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopbarFormWrapper = styled.div`
  margin-bottom: 30px;
  padding: 20px 40px 0px 40px;

  label {
    &:after {
      display: none;
    }
  }
  .ant-form-item {
    &:last-child {
      margin: 0;
    }
  }
`;

/**
 * Functional component that renders the modal for changing the user password.
 * Includes form fields for current and new passwords, and handles form submission
 * and validation. Displays a footer with buttons for cancelling or saving the changes.
 * @property {Function} changeUserPassword - a function that triggers the password change process.
 * @property {Function} onCancel - a function that closes the modal without saving changes.
 * @property {String} userId - the ID of the user whose password is being changed.
 * @property {Boolean} visible - a boolean indicating whether the modal is visible.
 * @property {Boolean} changeUserPasswordError - a boolean indicating if there was an error during password change.
 * @property {Boolean} changeUserPasswordLoading - a boolean indicating if the password change process is loading.
 */
const TopbarUserChangePasswordModal = ({
  changeUserPassword,
  onCancel,
  userId,
  visible,
  changeUserPasswordError,
  changeUserPasswordLoading
}) => {
  const [form] = Form.useForm();
  const { resetFields, getFieldsValue, validateFields } = form;
  const [isFieldsTouched, setIsFieldsTouched] = useState(false);

  // Cleanup form fields when the component unmounts
  useEffect(() => {
    return () => {
      resetFields();
    };
  }, [form]);

  const { currentPassword, newPassword } = getFieldsValue();

  const isFormCompleted = currentPassword && newPassword;

  // Handle form submission and password change logic
  const handleSubmit = () =>
    validateFields()
      .then(({ newPassword, currentPassword }) => {
        if (changeUserPasswordLoading) {
          return;
        }
        if (newPassword) {
          changeUserPassword(currentPassword, newPassword, userId);
          onCancel();
        } else {
          const { password_change_current_password_incorrect } = NOTIFICATION_MESSAGES;
          return renderNotification({
            type: 'error',
            message: password_change_current_password_incorrect
          });
        }
      })
      .catch((error) => {
        sentryCaptureValidationException(error);
      });

  const footer = (
    <TopbarModalFooterWrapper>
      <Button type="secondary" onClick={onCancel}>
        Abbrechen
      </Button>
      <Button
        disabled={!isFieldsTouched || (isFieldsTouched && !isFormCompleted)}
        type="primary"
        onClick={handleSubmit}
      >
        Speichern
      </Button>
    </TopbarModalFooterWrapper>
  );

  return (
    <Modal
      className="modal-no-padding"
      title="Passwort ändern"
      open={visible}
      maskClosable
      onCancel={onCancel}
      footer={footer}
    >
      <Form form={form} style={{ marginBottom: 26 }} onValuesChange={() => setIsFieldsTouched(Math.random())}>
        <TopbarFormWrapper>
          <Form.Item label="Aktuelles Passwort" name="currentPassword" rules={DEFAULT_RULES.INPUT_REQUIRED}>
            <InputPassword $hasErrors={changeUserPasswordError} />
          </Form.Item>
          <Form.Item label="Neues Passwort" name="newPassword" rules={DEFAULT_RULES.INPUT_REQUIRED}>
            <InputPassword $hasErrors={changeUserPasswordError} />
          </Form.Item>
        </TopbarFormWrapper>
        <PasswordRules withBottomMargin />
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  changeUserPasswordLoading: state.Users.get('changeUserPasswordLoading')
});

export default connect(mapStateToProps)(TopbarUserChangePasswordModal);
