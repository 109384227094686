import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  claims: [],
  claimsLoading: false,
  claimsError: false,
  claimsPerPage: 25,
  totalClaims: 0,
  claimsByPolicyId: [],
  claimsByPolicyIdLoading: false,
  claimsByPolicyIdError: false,
  choosableCreatedByOrganizations: [],
  choosableCreatedByOrganizationsLoading: false,
  choosableCreatedByOrganizationsError: false,
  claimsFiltersOrganizationsWithAccess: [],
  claimsFiltersOrganizationsWithAccessLoading: false,
  claimsFiltersOrganizationsWithAccessError: false,
  worklistClaims: [],
  worklistClaimsLoading: false,
  worklistClaimsError: false,
  worklistClaimsPerPage: 10,
  worklistTotalClaims: 0,
  mapBuildingsCount: 0,
  mapBuildingsLoading: false,
  mapBuildingsError: false,
  mapBuildings: [],
  claim: null,
  claimLoading: false,
  claimError: false,
  claimHistory: [],
  claimHistoryLoading: false,
  claimHistoryError: false,
  createClaimLoading: false,
  createClaimError: false,
  claimStatusError: false,
  claimStatusLoading: false,
  exportClaimsLoading: false,
  exportClaimsError: false,
  authorizedOrganizations: [],
  authorizedOrganizationsLoading: false,
  authorizedOrganizationsError: false,
  claimOrganizationsWithAccess: [],
  claimOrganizationsWithAccessLoading: false,
  claimOrganizationsWithAccessError: false,
  pdfReportLoading: false,
  pdfReportError: false,
  pdfClaimSummaryLoading: false,
  pdfClaimSummaryError: false,
  pdfClaimSummary: null,
  updateClaimError: false,
  updateClaimLoading: false,
  insuredRiskTypes: [],
  insuredRiskTypesLoading: false,
  insuredRiskTypesError: false,
  showClaimEditOverviewModal: false,
  lastVisitedClaimId: null,
  policyDetailsClaims: [],
  policyDetailsClaimsLoading: false,
  policyDetailsClaimsError: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_CLAIM_AUTHORIZED_ORGANIZATIONS:
      return state.set('authorizedOrganizationsLoading', true).set('authorizedOrganizationsError', false);
    case actions.GET_CLAIM_AUTHORIZED_ORGANIZATIONS_SUCCESS:
      return state
        .set('authorizedOrganizations', action.authorizedOrganizations)
        .set('authorizedOrganizationsLoading', false)
        .set('authorizedOrganizationsError', false);
    case actions.GET_CLAIM_AUTHORIZED_ORGANIZATIONS_ERROR:
      return state.set('authorizedOrganizationsLoading', false).set('authorizedOrganizationsError', true);
    case actions.LOAD_CLAIMS:
      return state.set('claimsLoading', true).set('claimsError', false);
    case actions.LOAD_CLAIMS_SUCCESS:
      return state
        .set('claims', action.allClaims)
        .set('claimsPerPage', parseInt(action.meta.perPage, 10))
        .set('totalClaims', parseInt(action.meta.total, 10))
        .set('claimsLoading', false)
        .set('claimsError', false);
    case actions.LOAD_CLAIMS_ERROR:
      return state.set('claimsLoading', false).set('claimsError', true);
    case actions.LOAD_WORKLIST_CLAIMS:
      return state.set('worklistClaimsLoading', true).set('worklistClaimsError', false);
    case actions.LOAD_WORKLIST_CLAIMS_SUCCESS:
      return state
        .set('worklistClaims', action.claims)
        .set('worklistClaimsPerPage', parseInt(action.meta.perPage, 10))
        .set('worklistTotalClaims', parseInt(action.meta.total, 10))
        .set('worklistClaimsLoading', false)
        .set('worklistClaimsError', false);
    case actions.LOAD_WORKLIST_CLAIMS_ERROR:
      return state.set('worklistClaimsLoading', false).set('worklistClaimsError', true);
    case actions.GET_CLAIM:
      return state.set('claimError', false).set('claimLoading', true);
    case actions.GET_CLAIM_SUCCESS:
      return state.set('claim', action.claim).set('claimError', false).set('claimLoading', false);
    case actions.GET_CLAIM_ERROR:
      return state.set('claimError', true).set('claimLoading', false);
    case actions.UPDATE_CLAIM_STATUS:
      return state.set('claimStatusError', false).set('claimStatusLoading', true);
    case actions.UPDATE_CLAIM_STATUS_SUCCESS:
      return state.set('claim', action.claim).set('claimStatusError', false).set('claimStatusLoading', false);
    case actions.UPDATE_CLAIM_STATUS_ERROR:
      return state.set('claimStatusError', true).set('claimStatusLoading', false);
    case actions.UPDATE_CLAIM:
      return state.set('updateClaimError', false).set('updateClaimLoading', true);
    case actions.UPDATE_CLAIM_SUCCESS:
      return state.set('claim', action.claim).set('updateClaimLoading', false).set('updateClaimError', false);
    case actions.UPDATE_CLAIM_ERROR:
      return state.set('updateClaimLoading', false).set('updateClaimError', true);
    case actions.CREATE_CLAIM:
      return state.set('createClaimLoading', true).set('createClaimError', false);
    case actions.CREATE_CLAIM_ERROR:
      return state.set('createClaimLoading', false).set('createClaimError', true);
    case actions.CREATE_CLAIM_SUCCESS:
      return state.set('createClaimLoading', false).set('createClaimError', false);
    case actions.CLEAR_CLAIM:
      return state.set('claim', null);
    case actions.GET_CLAIM_HISTORY:
      return state.set('claimHistoryLoading', true).set('claimHistoryError', false);
    case actions.GET_CLAIM_HISTORY_SUCCESS:
      return state
        .set('claimHistoryLoading', false)
        .set('claimHistoryError', false)
        .set('claimHistory', action.payload);
    case actions.GET_CLAIM_HISTORY_ERROR:
      return state.set('claimHistoryLoading', false).set('claimHistoryError', true);
    case actions.LOAD_MAP_BUILDINGS:
      return state.set('mapBuildingsLoading', true);
    case actions.LOAD_MAP_BUILDINGS_SUCCESS:
      return state
        .set(
          'mapBuildingsCount',
          action.mapBuildings.reduce((sum, { number_of_claims }) => sum + number_of_claims, 0)
        )
        .set('mapBuildingsLoading', false)
        .set('mapBuildings', action.mapBuildings);
    case actions.LOAD_MAP_BUILDINGS_ERROR:
      return state.set('mapBuildingsLoading', false).set('mapBuildingsError', true);
    case actions.LOAD_CLAIMS_BY_POLICY_ID:
      return state.set('claimsByPolicyIdLoading', true);
    case actions.LOAD_CLAIMS_BY_POLICY_ID_SUCCESS:
      return state.set('claimsByPolicyIdLoading', false).set('claimsByPolicyId', action.allClaimsByPolicyId);
    case actions.LOAD_CLAIMS_BY_POLICY_ID_ERROR:
      return state.set('claimsByPolicyIdLoading', false).set('claimsByPolicyIdError', true);
    case actions.LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS:
      return state.set('choosableCreatedByOrganizationsLoading', true);
    case actions.LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS_SUCCESS:
      return state
        .set('choosableCreatedByOrganizations', action.organizations)
        .set('choosableCreatedByOrganizationsLoading', false);
    case actions.LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS_ERROR:
      return state
        .set('choosableCreatedByOrganizationsError', true)
        .set('choosableCreatedByOrganizationsLoading', false);
    case actions.GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS:
      return state.set('claimsFiltersOrganizationsWithAccessLoading', true);
    case actions.GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS_SUCCESS:
      return state
        .set('claimsFiltersOrganizationsWithAccess', action.organizations)
        .set('claimsFiltersOrganizationsWithAccessLoading', false);
    case actions.GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS_ERROR:
      return state
        .set('claimsFiltersOrganizationsWithAccessError', true)
        .set('claimsFiltersOrganizationsWithAccessLoading', false);
    case actions.EXPORT_CLAIMS:
      return state.set('exportClaimsError', false).set('exportClaimsLoading', true);
    case actions.EXPORT_CLAIMS_SUCCESS:
      return state.set('exportClaimsError', false).set('exportClaimsLoading', false);
    case actions.EXPORT_CLAIMS_ERROR:
      return state.set('exportClaimsError', true).set('exportClaimsLoading', false);
    case actions.GET_CLAIM_ORGANIZATIONS_WITH_ACCESS:
      return state.set('claimOrganizationsWithAccessError', false).set('claimOrganizationsWithAccessLoading', true);
    case actions.GET_CLAIM_ORGANIZATIONS_WITH_ACCESS_SUCCESS:
      return state
        .set('claimOrganizationsWithAccess', action.organizations)
        .set('claimOrganizationsWithAccessError', false)
        .set('claimOrganizationsWithAccessLoading', false);
    case actions.GET_CLAIM_ORGANIZATIONS_WITH_ACCESS_ERROR:
      return state.set('claimOrganizationsWithAccessError', true).set('claimOrganizationsWithAccessLoading', false);
    case actions.CLEAR_CLAIM_ORGANIZATIONS_WITH_ACCESS:
      return state.set('claimOrganizationsWithAccess', []);
    case actions.CLEAR_CREATE_CLAIM_ERROR:
      return state.set('createClaimError', false);
    case actions.GET_PDF_REPORT:
      return state.set('pdfReportLoading', true).set('pdfReportError', false);
    case actions.GET_PDF_REPORT_SUCCESS:
      return state.set('pdfReportLoading', false).set('pdfReportError', false);
    case actions.GET_PDF_REPORT_ERROR:
      return state.set('pdfReportError', true).set('pdfReportLoading', false);
    case actions.GET_CLAIMS_INSURED_RISK_TYPES:
      return state.set('insuredRiskTypesLoading', true).set('insuredRiskTypesError', false);
    case actions.GET_CLAIMS_INSURED_RISK_TYPES_SUCCESS:
      return state
        .set('insuredRiskTypes', action.insuredRiskTypes)
        .set('insuredRiskTypesLoading', false)
        .set('insuredRiskTypesError', false);
    case actions.GET_CLAIMS_INSURED_RISK_TYPES_ERROR:
      return state.set('insuredRiskTypesError', true).set('insuredRiskTypesLoading', false);
    case actions.GET_CLAIM_PDF_SUMMARY:
      return state.set('pdfClaimSummaryLoading', true).set('pdfClaimSummaryError', false);
    case actions.GET_CLAIM_PDF_SUMMARY_SUCCESS:
      return state
        .set('pdfClaimSummaryLoading', false)
        .set('pdfClaimSummaryError', false)
        .set('pdfClaimSummary', action.pdfClaimSummary);
    case actions.GET_CLAIM_PDF_SUMMARY_ERROR:
      return state.set('pdfClaimSummaryError', true).set('pdfClaimSummaryLoading', false);
    case actions.RESET_CLAIM_PDF_SUMMARY:
      return state.set('pdfClaimSummary', null);
    case actions.CLEAR_CLAIM_HISTORY:
      return state.set('claimHistory', []);
    case actions.CLEAR_CLAIM_INSURED_RISK_TYPES:
      return state.set('insuredRiskTypes', []);
    case actions.SET_SHOW_CLAIM_EDIT_OVERVIEW_MODAL:
      return state.set('showClaimEditOverviewModal', action.showModal);
    case actions.SET_LAST_VISITED_CLAIM_ID:
      return state.set('lastVisitedClaimId', action.claimId);
    case actions.RESET_LAST_VISITED_CLAIM_ID:
      return state.set('lastVisitedClaimId', null);
    case actions.GET_POLICY_DETAILS_CLAIMS:
      return state.set('policyDetailsClaimsLoading', true).set('policyDetailsClaimsError', false);
    case actions.GET_POLICY_DETAILS_CLAIMS_SUCCESS:
      return state
        .set('policyDetailsClaims', action.claims)
        .set('policyDetailsClaimsLoading', false)
        .set('policyDetailsClaimsError', false);
    case actions.GET_POLICY_DETAILS_CLAIMS_ERROR:
      return state.set('policyDetailsClaimsLoading', false).set('policyDetailsClaimsError', true);
    default:
      return state;
  }
};

export default reducer;
