import Input from 'antd/es/input';
import React from 'react';
import styled from 'styled-components';
import { IconSearch } from '../Icons';

const { Search } = Input;

const InputSearchWrapper = styled(Search)`
  .ant-input-group-addon {
    display: none;
  }

  .ant-input-affix-wrapper {
    background-color: ${(props) =>
      props.version === 'light' ? 'var(--greyscale-white)' : 'var(--greyscale-dark-white)'};
    border: 1px solid var(--greyscale-concrete);
    border-radius: 25px !important;
    padding: 8px 15px;

    &:hover {
      border: 1px solid var(--primary-oqio-blue);
      box-shadow: none !important;
    }

    .ant-input-suffix {
      height: 24px;
    }
  }

  .ant-input-affix-wrapper-focused {
    border: 1px solid var(--primary-oqio-blue);
    box-shadow: var(--box-shadow-oqio-blue) !important;

    &:hover {
      border: 1px solid var(--primary-oqio-blue);
      box-shadow: var(--box-shadow-oqio-blue) !important;
    }
  }

  &.ant-input-search {
    background-color: ${(props) =>
      props.version === 'light' ? 'var(--greyscale-white)' : 'var(--greyscale-dark-white)'};
    border: none;
    border-radius: 25px;
    color: ${(props) => (props.version === 'light' ? 'var(--greyscale-concrete)' : 'var(--greyscale-shade)')};
    height: ${(props) => (props.size === 'small' ? 30 : 40)}px;
    position: relative;
    width: 420px;

    .ant-input,
    .ant-input:not(:first-child) {
      background-color: ${(props) =>
        props.version === 'light' ? 'var(--greyscale-white)' : 'var(--greyscale-dark-white)'};
      border: none;
      box-shadow: none;
      color: var(--greyscale-blackish);
      font-size: var(--font-size-regular);
      line-height: 24px;
      padding-left: 5px;

      &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: var(--greyscale-shade);
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: var(--greyscale-shade);
        opacity: 1;
      }
      &::placeholder {
        /* Most modern browsers support this now. */
        color: var(--greyscale-shade);
      }
    }

    .ant-input-clear-icon {
      font-size: var(--font-size-heading-3);
    }
  }
`;

const InputSearch = React.forwardRef(({ version, size, ...restProps }, ref) => (
  <InputSearchWrapper prefix={<IconSearch />} allowClear size={size} version={version} ref={ref} {...restProps} />
));

export default InputSearch;
