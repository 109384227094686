import styled from 'styled-components';

const AuthenticationLayoutStyleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-regular);
  justify-content: flex-start;
  min-height: 100vh;
  position: relative;
  width: 100%;

  .logo-wrapper {
    width: auto;
  }

  .terms-menu {
    position: absolute;
    bottom: 20px;
    text-align: center;
    z-index: 5;
  }

  .oqio-link {
    color: var(--greyscale-white);
    cursor: default;
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-medium);

    &:focus {
      outline: none;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  .valid-link {
    cursor: pointer;

    &:hover {
      color: var(--greyscale-white);
    }
  }
`;

const Background = styled.img`
  bottom: 0;
  height: 100vh;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const OqioPageRedirectLink = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
`;

const LogoWrapper = styled.div`
  color: var(--greyscale-white);
  left: 30px;
  position: absolute;
  top: 10px;
  z-index: 5;
`;

const RedirectLink = styled.a`
  align-items: center;
  color: var(--greyscale-white);
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-regular);
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--greyscale-white);
  }

  svg {
    margin-left: 6px;
    path {
      stroke: var(--greyscale-white);
    }
  }
`;

export { AuthenticationLayoutStyleWrapper, Background, OqioPageRedirectLink, LogoWrapper, RedirectLink };
