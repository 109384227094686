import styled from 'styled-components';
import AntCheckbox from 'antd/es/checkbox';

const Checkbox = styled(AntCheckbox)`
  &.ant-checkbox-wrapper {
    display: flex;
    flex-direction: ${(props) => (props.$labelLeft ? 'row-reverse' : 'row')};
    gap: 6px;

    .ant-checkbox {
      margin-top: 0;
      align-self: flex-start;

      .ant-checkbox-inner {
        height: 20px;
        width: 20px;

        &:after {
          left: 6px;
          top: 9px;
        }
      }
    }

    span {
      padding: 0;
    }

    &.ant-checkbox-wrapper-disabled {
      cursor: default;

      .ant-checkbox {
        .ant-checkbox-inner {
          background-color: var(--greyscale-dark-white);
          border-color: var(--greyscale-light-grey);
        }
      }
    }
  }
`;

const CheckboxGroup = styled(AntCheckbox.Group)`
  &.ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .ant-checkbox-wrapper {
      gap: 6px;
      margin: 0;

      .ant-checkbox {
        margin-top: 0;
        align-self: flex-start;

        .ant-checkbox-inner {
          height: 20px;
          width: 20px;

          &:after {
            left: 6px;
            top: 9px;
          }
        }
      }

      span {
        padding: 0;
      }
    }
  }
`;

Checkbox.Group = CheckboxGroup;

export default Checkbox;
