import React from 'react';
import AntDropdown from 'antd/es/dropdown';
import styled from 'styled-components';

const StyledDropdown = styled(AntDropdown)`
  &.worklist-claim-messages-filter {
    align-items: center;
    color: var(--primary-oqio-blue);
    display: flex;
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-medium);
    gap: 7px;
    height: 40px;
    line-height: var(--font-line-height-regular);

    &:not(.active) {
      svg {
        path {
          stroke: var(--primary-oqio-blue);
        }
      }
    }

    &.active {
      svg {
        path {
          fill: var(--primary-oqio-blue);
        }
      }
    }

    &:hover {
      color: var(--primary-oqio-blue-hover);
      cursor: pointer;

      &:not(.active) {
        svg {
          path {
            stroke: var(--primary-oqio-blue-hover);
          }
        }
      }

      &.active {
        svg {
          path {
            fill: var(--primary-oqio-blue-hover);
          }
        }
      }
    }
  }
`;

export const DropdownWrapper = styled.div`
  background-color: var(--greyscale-white);
  border-radius: var(--border-radius-small);
  box-shadow:
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-regular);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  &.max-height {
    max-height: 350px;
    overflow: auto;
    scrollbar-color: rgba(136, 136, 136, 0.3) transparent;
    scrollbar-width: thin;
  }
`;

export const DropdownListItem = styled.div`
  cursor: pointer;
  padding: 2px;
`;

export const DropdownListItemDivider = styled.div`
  &:not(:last-child) {
    border-bottom: solid 1px var(--greyscale-dark-white);
  }
`;

export const DropdownListButton = styled.button`
  background-color: white;
  border: none;
  border-radius: var(--border-radius-regular);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  padding: 10px 40px;

  svg {
    path {
      stroke: var(--primary-oqio-blue);
    }
  }

  &.active {
    font-weight: var(--font-weight-medium);
    padding-left: 14px;

    svg {
      margin-right: 6px;
    }
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;
const Dropdown = ({ trigger = 'click', arrow = { pointAtCenter: true }, placement = 'bottomRight', ...rest }) => {
  return <StyledDropdown trigger={trigger} arrow={arrow} placement={placement} {...rest} />;
};

export default Dropdown;
