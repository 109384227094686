import AntAvatar from 'antd/es/avatar';
import styled from 'styled-components';

const Avatar = styled(AntAvatar)`
  &.worklist-user-avatar {
    align-items: center;
    background-color: var(--greyscale-blackish);
    display: flex;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semi-bold);
    height: 30px;
    justify-content: center;
    line-height: var(--font-line-height-small);
    margin-bottom: 10px;
    width: 30px;
  }

  &.worklist-deleted-user-avatar {
    align-items: center;
    background-color: var(--greyscale-light-grey);
    display: flex;
    height: 30px;
    justify-content: center;
    margin-bottom: 10px;
    width: 30px;
    align-items: center;
    border: 1px solid var(--greyscale-white);

    span {
      left: initial;
      position: relative;
      transform: initial !important;
      transform-origin: unset;
      display: flex;

      svg {
        circle,
        path {
          stroke: var(--greyscale-white);
        }
        ellipse {
          fill: var(--greyscale-white);
        }
      }
    }
  }

  &.worklist-system-avatar {
    background-color: var(--greyscale-white);
    border-radius: var(--border-radius-small);
    height: 30px;
    margin-bottom: 10px;
    width: 30px;

    .ant-avatar-string {
      transform: none !important;
      width: 30px;
      height: 30px;
    }

    span {
      left: initial;
      position: relative;
      transform: initial;
      transform-origin: unset;
    }
  }
`;

export default Avatar;
