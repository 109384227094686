import styled from 'styled-components';
import { windowMaxWidth } from './breakpoints';

const MainWrapper = styled.div`
  margin: 0 20px;
  width: calc(100% - 40px);

  @media ${windowMaxWidth.splitScreen} {
    overflow: hidden;
  }
`;

const PageHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: flex-start;
  padding: 0 20px;
`;

const NavigationWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: 40px;
`;

const NavigationItem = styled.div`
  border-bottom: 2px solid ${(props) => (props.$isActive ? 'var(--greyscale-blackish)' : 'transparent')};
  color: ${(props) => (props.$isActive ? 'var(--greyscale-blackish)' : 'var(--greyscale-shade)')};
  cursor: pointer;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  padding: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 181px);
  min-height: 400px;

  @media ${windowMaxWidth.splitScreen} {
    flex-direction: column;
  }
`;

const LeftColumnWrapper = styled.div`
  background-color: var(--greyscale-white);
  border: solid 1px var(--greyscale-dark-white);
  border-radius: var(--border-radius-regular);
  max-width: 340px;
  min-width: 340px;

  @media ${windowMaxWidth.splitScreen} {
    display: none;
  }
`;

const MainContentWrapper = styled.div`
  border: solid 1px transparent;
  border-radius: var(--border-radius-regular);
  height: 100%;
  margin-left: 20px;
  min-width: 850px;
  overflow: hidden;
  width: calc(100vw - 346px);

  @media (${windowMaxWidth.splitScreen}) {
    height: calc(100vh - 175px);
    margin: 0;
    min-width: calc(100vw - 40px);

    .ant-table-wrapper {
      min-height: 0;
    }
  }
`;

const AdministrationLayout = {
  MainWrapper,
  PageHeader,
  NavigationWrapper,
  NavigationItem,
  ContentWrapper,
  LeftColumnWrapper,
  MainContentWrapper
};

export default AdministrationLayout;
