const DEFAULT_ROUTE_QUERY_VALUES = {
  page_size: 25,
  page: 1,
  insured_risk_type: 'building',
  search: '',
  sort: '-updated_at',
  status: [],
  housing_stock_id: []
};

const ATTACHMENT_MAX_SIZES = {
  byte: 20971520,
  megaByte: 20
};

const MAIN_NAVIGATION_HEIGHT = 60;
const PAGE_HEADER_HEIGHT = 100;
const PAGE_BOTTOM_MARGIN_HEIGHT = 20;
const DETAILS_NAVIGATION_HEIGHT = 50;
const DETAILS_TAB_MARGIN = 20;
const BORDER_HEIGHT = 1;

const ATTACHMENT_TYPES = [
  {
    key: 'claimPdfReport',
    label: 'Schadenakte'
  },
  {
    key: 'documents',
    label: 'Dokumente'
  },
  {
    key: 'settlements',
    label: 'Regulierung'
  },
  {
    key: 'ownFile',
    label: 'Eigene Datei'
  }
];

const ALLOWED_FILE_TYPES = {
  default: {
    description: 'Akzeptiertes Dateiformat: PDF, JPG, JPEG, PNG',
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg']
    }
  },
  legal: {
    description: 'Akzeptiertes Dateiformat: PDF',
    accept: {
      'application/pdf': ['.pdf']
    }
  },
  image: {
    description: 'Akzeptiertes Dateiformat: JPG, JPEG, PNG',
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg']
    }
  }
};

const MENU_ITEMS = [
  {
    key: 'claims',
    label: 'Schäden',
    link: '/claims/list'
  },
  {
    key: 'buildings',
    label: 'Objekte',
    link: '/buildings/list',
    accessRights: ['buildings_ui']
  },
  {
    key: 'policies',
    label: 'Verträge',
    link: '/policies/list',
    alternativeLink: '/policy',
    accessRights: ['policies_ui']
  },
  {
    key: 'reporting',
    label: 'Reporting',
    link: '/reporting',
    accessRights: ['reporting_ui'],
    hideOnMobile: true
  },
  {
    key: 'administration',
    link: '/administration/organizations/users',
    label: 'Verwaltung',
    accessRights: ['administration_ui'],
    hideOnMobile: true
  }
];

const SETTINGS_MENU_ITEMS = [
  {
    index: 0,
    id: 'tenant_logo',
    name: 'Logo der Anwendung',
    description: 'Quadratische Bildatei im Format PNG oder JPG mit einer Mindestauflösung von 196×196px',
    actionType: 'file_upload'
  },
  {
    index: 1,
    id: 'tenant_name',
    name: 'Titel der Anwendung',
    description: 'Anzeige in der Hauptnavigation der Anwendung',
    actionType: 'input_text'
  },
  {
    index: 2,
    id: 'imprint',
    name: 'Impressum',
    description: 'Datei im PDF Format',
    actionType: 'file_upload'
  },
  {
    index: 3,
    id: 'privacy_statement',
    name: 'Datenschutz',
    description: 'Datei im PDF Format',
    actionType: 'file_upload'
  },
  {
    index: 4,
    id: 'terms_of_use',
    name: 'Nutzungsbedingungen',
    description: 'Datei im PDF Format',
    actionType: 'file_upload'
  },
  {
    index: 5,
    id: 'simple_claim_ui_enabled',
    name: 'Freie Schadenanlage',
    description:
      'Wenn keine Stammdaten (versicherte Objektadressen und / oder Versicherungsdaten) auf der Plattform hinterlegt sind, können dennoch Schäden gemeldet werden. Diese sind dann vor Versand an Versicherer mit Versicherungsdaten zu ergänzen.',
    actionType: 'switch'
  }
];

const ADMINISTRATION_MENU_ITEMS = [
  {
    path: 'organizations/users',
    label: 'Organisationen',
    accessRights: ['administration_organizations_ui']
  },
  {
    path: 'housing-stocks',
    label: 'Bestände',
    accessRights: ['administration_housing_stocks_ui']
  },
  {
    path: 'settings',
    label: 'Einstellungen',
    accessRights: ['administration_settings_ui']
  }
];

const CLAIM_DEFAULT_INSURED_RISK_TYPE = 'default';

const CLAIM_DETAILS_MENU_ITEMS = [
  {
    label: 'Übersicht',
    key: 'main',
    insuredRiskType: [CLAIM_DEFAULT_INSURED_RISK_TYPE, 'building', 'glass', 'construction', 'liability']
  },
  {
    label: 'Anspruchsteller',
    key: 'claimants',
    insuredRiskType: ['liability']
  },
  {
    label: 'Verursacher',
    key: 'causer',
    insuredRiskType: [CLAIM_DEFAULT_INSURED_RISK_TYPE, 'building', 'glass', 'construction']
  },
  {
    label: 'Geschädigte',
    key: 'damaged_units',
    insuredRiskType: [CLAIM_DEFAULT_INSURED_RISK_TYPE, 'building', 'glass', 'construction']
  },
  {
    label: 'Dokumente',
    key: 'documents',
    insuredRiskType: [CLAIM_DEFAULT_INSURED_RISK_TYPE, 'building', 'glass', 'construction', 'liability']
  },
  {
    label: 'Regulierung',
    key: 'settlements',
    insuredRiskType: [CLAIM_DEFAULT_INSURED_RISK_TYPE, 'building', 'glass', 'construction', 'liability']
  },
  {
    label: 'Beteiligte',
    key: 'participants',
    insuredRiskType: [CLAIM_DEFAULT_INSURED_RISK_TYPE, 'building', 'glass', 'construction', 'liability']
  },
  {
    label: 'Verlauf',
    key: 'history',
    insuredRiskType: [CLAIM_DEFAULT_INSURED_RISK_TYPE, 'building', 'glass', 'construction', 'liability']
  }
];

const POLICY_DETAILS_MENU_ITEMS = [
  {
    label: 'Übersicht',
    key: 'main',
    value: 'main'
  },
  {
    label: 'Gefahren',
    key: 'risks',
    value: 'risks'
  },
  {
    label: 'Dokumente',
    key: 'documents',
    value: 'documents'
  },
  {
    label: 'Organisationen',
    key: 'organizations',
    value: 'organizations'
  },
  {
    label: 'Objekte',
    key: 'buildings',
    value: 'buildings'
  },
  {
    label: 'Schäden',
    key: 'claims',
    value: 'claims'
  }
];

const BUILDING_DETAILS_MENU_ITEMS = [
  {
    label: 'Übersicht',
    key: 'main',
    value: 'main'
  },
  {
    label: 'Verträge',
    key: 'policies',
    value: 'policies'
  }
];

const CLAIM_STATUSES = [
  {
    value: 'open',
    label: 'Offen',
    icon: 'open'
  },
  {
    value: 'closed',
    label: 'Geschlossen',
    icon: 'closed'
  },
  {
    value: 'rejected',
    label: 'Abgelehnt / Storniert',
    icon: 'rejected'
  }
];

const CLAIMS_VIEW_OPTIONS = [
  {
    value: 'list',
    label: 'Liste'
  },
  {
    value: 'map',
    label: 'Karte'
  }
];

const CLAIMS_SORT_OPTIONS = [
  {
    label: 'Zuletzt geändert',
    value: '-updated_at'
  },
  {
    label: 'Erstellungsdatum',
    value: '-created_at'
  },
  {
    label: 'Schadentag',
    value: '-date_of_damage'
  }
];

const POLICIES_SORT_OPTIONS = [
  {
    label: 'Versicherung',
    value: 'insurance_org'
  },
  {
    label: 'Anzahl der Objekte',
    value: '-building_count'
  },
  {
    label: 'Zuletzt geändert',
    value: '-updated_at_policy_and_relations'
  }
];

const SETTLEMENT_STATUSES = [
  {
    id: 'open',
    name: 'Offen'
  },
  {
    id: 'closed',
    name: 'Geschlossen'
  },
  {
    id: 'rejected',
    name: 'Abgelehnt / Storniert'
  }
];

const SETTLEMENT_TYPE_INVOICE = 'invoice';
const SETTLEMENT_TYPE_QUOTATION = 'quotation';

const CAUSER_TYPES = [
  {
    value: 'no_causer',
    label: 'Kein Verursacher'
  },
  {
    value: 'tenant',
    label: 'Mieter'
  },
  {
    value: 'contractor',
    label: 'Fremdfirma'
  },
  {
    value: 'policyholder',
    label: 'Versicherungnehmer'
  },
  {
    value: 'unknown',
    label: 'Unbekannt'
  }
];

const DAMAGED_UNIT_TYPES = [
  {
    value: 'housing_unit',
    label: 'Wohneinheit'
  },
  {
    value: 'commercial_unit',
    label: 'Gewerbeeinheit'
  },
  {
    value: 'other',
    label: 'Sonstiges'
  }
];

const INSURANCE_STATUSES = [
  {
    value: 'has_insurance',
    label: 'Vorhanden'
  },
  {
    value: 'has_no_insurance',
    label: 'Nicht vorhanden'
  },
  {
    value: 'information_refused',
    label: 'Auskunft verweigert'
  }
];

const LIABILITY_INSURANCE_STATUSES = [
  {
    value: 'has_liability_insurance',
    label: 'Vorhanden'
  },
  {
    value: 'has_no_liability_insurance',
    label: 'Nicht vorhanden'
  },
  {
    value: 'information_refused',
    label: 'Auskunft verweigert'
  }
];

const CLAIMANT_RELATION_TO_POLICYHOLDER = [
  {
    value: 'tenancy',
    label: 'Mietverhältnis'
  },
  {
    value: 'employment',
    label: 'Arbeitsverhältnis'
  },
  {
    value: 'kinship',
    label: 'Verwandtschaft'
  },
  {
    value: 'none',
    label: 'Keines'
  }
];

const CLAIM_LIABILITY_DAMAGE_TO_TYPES = [
  {
    value: 'person',
    label: 'Personenschaden'
  },
  {
    value: 'property',
    label: 'Sachschaden'
  },
  {
    value: 'personAndProperty',
    label: 'Personen- und Sachschaden'
  }
];

const ORGANISATIONS_TYPES = [
  {
    id: 0,
    label: 'Portalbetreiber',
    value: 'licensee',
    canBeCreated: false,
    availableRoles: [
      { id: 0, text: 'Admin', value: 'admin' },
      { id: 1, text: 'Sachbearbeiter', value: 'clerk' },
      { id: 2, text: 'Manager', value: 'manager' }
    ]
  },
  {
    id: 1,
    label: 'Versicherung',
    value: 'insurance',
    canBeCreated: true,
    availableRoles: [
      { id: 0, text: 'Sachbearbeiter', value: 'clerk' },
      { id: 1, text: 'Manager', value: 'manager' }
    ]
  },
  {
    id: 2,
    label: 'Immobilienverwaltung',
    value: 'housing_company',
    canBeCreated: true,
    availableRoles: [
      { id: 0, text: 'Sachbearbeiter', value: 'clerk' },
      { id: 1, text: 'Manager', value: 'manager' }
    ]
  },
  {
    id: 3,
    label: 'Handwerk',
    value: 'service_provider',
    canBeCreated: true,
    availableRoles: [
      { id: 0, text: 'Sachbearbeiter', value: 'clerk' },
      { id: 1, text: 'Manager', value: 'manager' }
    ]
  }
];

const FILTER_OPTIONS = [
  {
    key: 'system_created',
    label: 'Änderungen'
  },
  {
    key: 'with_reminder',
    label: 'Erinnerungen'
  },
  {
    key: 'is_active',
    label: 'Ungelesene'
  },
  {
    key: '',
    label: 'Alle'
  }
];

// These are the containers that are always shown in the file container list:
// Images of the damage (Bilder zum Schadensausmaß), Other documents (Sonstige Dokumente)
const DEFAULT_FILE_CONTAINER_IDS = ['b5d1f89c-9cbc-44db-973e-5a7307571853', 'd9e22196-68c8-43da-b343-801416650921'];

const ERROR_TYPES = {
  ERROR_TECHNICAL_PROBLEM: {
    type: 'technicalProblem',
    headline: 'Leider gab es ein technisches Problem',
    message: 'Sollte der Fehler wiederholt auftreten, wenden Sie sich bitte an support@oqio.de.'
  },
  ERROR_NOT_AUTHORIZED: {
    type: 'notAuthorized',
    headline: 'Fehlende Berechtigung',
    message: 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen.'
  },
  ERROR_PAGE_NOT_FOUND: {
    type: 'notFound',
    headline: 'Seite nicht gefunden',
    message: 'Die Seite, die Sie suchen, wurde nicht gefunden.'
  }
};

const IMPORT_TABLE_ROW_STATUSES = {
  new: {
    label: 'Neu',
    color: 'var(--primary-oqio-green)'
  },
  invalid: {
    label: 'Ungültig',
    color: 'var(--primary-oqio-red)'
  },
  no_changes: {
    label: 'Unverändert',
    color: 'var(--greyscale-shade)'
  },
  updated: {
    label: 'Aktualisiert',
    color: 'var(--primary-oqio-blue)'
  }
};

const IMPORT_SUMMARY_ROWS = [
  {
    key: 'new_building_count',
    entityNameSingular: 'Gebäude',
    entityNamePlural: 'Gebäude',
    operation: 'erstellt'
  },
  {
    key: 'new_policy_count',
    entityNameSingular: 'Vertrag',
    entityNamePlural: 'Verträge',
    operation: 'erstellt'
  },
  {
    key: 'updated_building_count',
    entityNameSingular: 'Gebäude',
    entityNamePlural: 'Gebäude',
    operation: 'aktualisiert'
  },
  {
    key: 'updated_policy_count',
    entityNameSingular: 'Vertrag',
    entityNamePlural: 'Verträge',
    operation: 'aktualisiert'
  },
  {
    key: 'new_building_to_policy_count',
    entityNameSingular: 'Vertrag',
    entityNamePlural: 'Verträge',
    operation: 'zugeordnet'
  }
];

const CLAIM_TYPE_FILTER_LABLE_COLORS = {
  'dfbee8a1-0fcf-48a7-8dfa-18a83a5aa34b': 'var(--damage-type-construction)', // 'construction',
  'cc1e6337-2eb8-409b-b7bc-704a8aa8d3a4': 'var(--damage-type-fire)', // 'fire',
  '17236d3c-3daa-43a2-8048-9b5f571ec480': 'var(--damage-type-water)', // 'water',
  '4010e99c-fd07-4f87-acfc-6e3b7dd7dd88': 'var(--damage-type-storm)', // 'storm',
  'f7c11fda-2c9e-4036-9637-557370dd4c7c': 'var(--damage-type-malicious)', // 'malicious',
  'f1f28d6e-9e26-4171-b345-0d702c137d51': 'var(--damage-type-glass)', // 'glass',
  '49504569-2b24-421c-b645-d7bbb38912b0': 'var(--damage-type-glass)', // 'glass',
  '4dc7e85c-84f0-4e3f-ae21-644062316411': 'var(--damage-type-house)', // 'house',
  '11672aae-9566-4431-9864-6b70cda90b56': 'var(--damage-type-liability-construction)', // 'liabilityConstruction',
  '35bb4466-02ee-461f-a35e-c05285bec3df': 'var(--damage-type-liability-facility)', // 'liabilityFacility',
  '19ca53e6-6d98-403e-9549-0d98a0b4eb22': 'var(--damage-type-liability-property)', // 'liabilityProperty',
  '2d5907c7-a6cd-4558-856f-08d754816464': 'var(--damage-type-liability-water)', // 'liabilityWater',
  '51126254-8016-4e52-9ce1-3c1995d91852': 'var(--damage-type-other)' // 'other',
};

const FILTER_COUNT_SLIDER_MARKS = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '20',
  12: '30',
  13: '40',
  14: '50',
  15: '100'
};

// this is the center of germany, if the building has no coordinates
const DEFAULT_BUILDING_COORDINATES = {
  latitude: 51.163375,
  longitude: 10.447683
};

const BUILDING_EXTERNAL_ORIGIN_FIELDS = ['street', 'zipcode', 'town', 'economic_unit', 'external_id'];

const MAX_REMINDER_MESSAGE_LENGTH = 240;

const NON_INSURED_COSTS = 'Nicht versicherte Kosten';
const DIFFERENCE = 'Differenz';
const NO_RISK_SELECTED_TITLE = 'Keine Gefahr ausgewählt';

const INFORMATION_BANNER_TEXT =
  'Einige Daten stammen aus einer anderen Software. Bitte nehmen Sie erforderliche Änderungen dort vor.';

// here are the logos of the insurance organizations
// the key is the name of the organization and the same like in the INSURANCE_ORGANIZATIONS
const INSURANCE_ORGANIZATIONS_LOGOS = {
  Advania: require('../components/ui/InsuranceLogos/Advania.png'),
  Allianz: require('../components/ui/InsuranceLogos/Allianz.png'),
  AlteLeipziger: require('../components/ui/InsuranceLogos/AlteLeipziger.png'),
  AXA: require('../components/ui/InsuranceLogos/Axa.png'),
  Baloise: require('../components/ui/InsuranceLogos/Baloise.png'),
  Concordia: require('../components/ui/InsuranceLogos/Concordia.png'),
  Condor: require('../components/ui/InsuranceLogos/Condor.png'),
  Continentale: require('../components/ui/InsuranceLogos/Continentale.png'),
  Dialog: require('../components/ui/InsuranceLogos/Dialog.png'),
  Domcura: require('../components/ui/InsuranceLogos/Domcura.png'),
  Ergo: require('../components/ui/InsuranceLogos/Ergo.png'),
  Feuersozietaet: require('../components/ui/InsuranceLogos/Feuersozietaet.png'),
  Generali: require('../components/ui/InsuranceLogos/Generali.png'),
  Gothaer: require('../components/ui/InsuranceLogos/Gothaer.png'),
  Grundeigentuemer: require('../components/ui/InsuranceLogos/Grundeigentuemer.png'),
  Haftpflichtkasse: require('../components/ui/InsuranceLogos/Haftpflichtkasse.png'),
  Hausbesitzer: require('../components/ui/InsuranceLogos/Hausbesitzer.png'),
  HDI: require('../components/ui/InsuranceLogos/HDI.png'),
  Helvetia: require('../components/ui/InsuranceLogos/Helvetia.png'),
  HVS: require('../components/ui/InsuranceLogos/HVS.png'),
  Interlloyd: require('../components/ui/InsuranceLogos/Interlloyd.png'),
  LVM: require('../components/ui/InsuranceLogos/Lvm.png'),
  Mannheimer: require('../components/ui/InsuranceLogos/Mannheimer.png'),
  Nuernberger: require('../components/ui/InsuranceLogos/Nuernberger.png'),
  OeffentlicheOldenburg: require('../components/ui/InsuranceLogos/OeffentlicheOldenburg.png'),
  OeffentlicheSachversicherungBraunschweig: require('../components/ui/InsuranceLogos/OeffentlicheSachversicherungBraunschweig.png'),
  OeffentlicheVersicherungBremen: require('../components/ui/InsuranceLogos/OeffentlicheVersicherungBremen.png'),
  Provinzial: require('../components/ui/InsuranceLogos/Provinzial.png'),
  RheinlandVersicherungen: require('../components/ui/InsuranceLogos/RheinlandVersicherungen.png'),
  RV: require('../components/ui/InsuranceLogos/RV.png'),
  SignalIduna: require('../components/ui/InsuranceLogos/SignalIduna.png'),
  SparkassenVersicherung: require('../components/ui/InsuranceLogos/SparkassenVersicherung.png'),
  VGH: require('../components/ui/InsuranceLogos/VGH.png'),
  Wuerttembergische: require('../components/ui/InsuranceLogos/Wuerttembergische.png'),
  Zurich: require('../components/ui/InsuranceLogos/Zurich.png')
};

// here are the insurance organizations with their different names
// the key is the name of the organization and the same like in the INSURANCE_ORGANIZATIONS_LOGOS
const INSURANCE_ORGANIZATIONS = {
  Advania: ['Advania', 'Advania Versicherung', 'Advania Falko Kortylak'], // Advania GmbH
  Allianz: [
    'Allianz',
    'Allianz Versicherung',
    'Allianz Sachschaden',
    'Allianz Versicherungs AG',
    'Allianz Versicherungs-AG', // Offizielle Schreibweise
    'Allianz Esa GmbH' // Allianz-Esa
  ], // Allianz
  AlteLeipziger: ['Alte Leipziger', 'Alte Leipziger Versicherung'], // Alte Leipziger
  AXA: ['AXA', 'AXA Versicherung', 'AXA Versicherung AG'], // AXA
  Baloise: ['Baloise', 'Baloise Versicherung', 'Baloise Sachversicherung AG'], // Baloise
  Concordia: ['Concordia', 'Concordia Versicherung', 'Concordia Versicherungs-Gesellschaft auf Gegenseitigkeit'], // Concordia
  Condor: ['Condor', 'Condor Versicherungen', 'Condor Allgemeine Versicherungs-AG'], // Condor
  Continentale: ['Continentale', 'Continentale Versicherung', 'Die Continentale'], // Continentale
  Dialog: ['Dialog', 'Dialog Versicherung', 'Dialog Versicherung AG'], // Dialog
  Domcura: ['Domcura', 'Domcura Versicherung'], // Domcura
  Ergo: ['Ergo', 'Ergo Versicherung', 'Ergo Versicherung AG'], // Ergo
  Feuersozietaet: ['Feuersozietät'], // Feuersozietät
  Generali: ['Generali', 'Generali Versicherung', 'Generali Deutschland Versicherung AG'], // Generali
  Gothaer: ['Gothaer', 'Gothaer Versicherungen', 'Gothaer Allgemeine Versicherung'], // Gothaer
  Grundeigentuemer: [
    'Grundeigentümer',
    'Grundeigentümer Versicherung',
    'Grundeigentümer-Versicherung',
    'Grundeigentümerversicherung',
    'GEV'
  ], // GEV
  Haftpflichtkasse: ['Haftpflichtkasse', 'Die Haftpflichtkasse'], // Haftpflichtkasse
  Hausbesitzer: ['Hausbesitzer', 'Hausbesitzer Versicherung', 'Hausbesitzer-Versicherung', 'bhvg'], // Hausbesitzer Versicherung
  HDI: ['HDI', 'HDI Versicherung', 'HDI Global SE', 'HDI Versicherung AG'], // HDI
  Helvetia: ['Helvetia', 'Helvetia Versicherung', 'Helvetia Schweizerische Versicherungsgesellschaft'], // Helvetia
  HVS: ['HVS', 'HVS Versicherung', 'HVS AG'], // HVS AG
  Interlloyd: ['Interlloyd', 'Interlloyd Versicherungen'], // Interlloyd
  LVM: ['LVM', 'LVM Landwirtschaftlicher Versicherungsverein Münster a.G.'], // LVM
  Mannheimer: ['Mannheimer', 'Mannheimer Versicherung', 'Mannheimer Versicherung AG'], // Mannheimer
  Nuernberger: [
    'Nürnberger',
    'Nuernberger',
    'Nürnberger Versicherung',
    'Nuernberger Versicherung',
    'NÜRNBERGER Allgemeine Versicherung'
  ], // Nürnberger
  OeffentlicheOldenburg: ['Öffentliche Oldenburg'], // Öffentliche Oldenburg
  OeffentlicheSachversicherungBraunschweig: [
    'Öffentliche Sachversicherung Braunschweig',
    'Öffentliche Versicherung Braunschweig'
  ], // Öffentliche Braunschweig'
  OeffentlicheVersicherungBremen: ['ÖVB', 'ÖVB Versicherung'], // ÖVB
  Provinzial: [
    'Provinzial',
    'Provinzial Versicherung',
    'Provinzial Nord Brandkasse Aktiengesellschaft',
    'Provinzial Versicherung AG'
  ], // Provinzial
  RheinlandVersicherungen: ['RheinLand Versicherungen'], // RheinLand
  RV: ['R+V', 'R + V', 'R+V Versicherung', 'R+V Allgemeine Versicherung AG'], // R+V
  SignalIduna: ['Signal Iduna', 'Signal Iduna Versicherung'], // Signal Iduna'
  SparkassenVersicherung: ['Sparkassen Versicherung', 'SV Sparkassen-Versicherung'], // SV Sparkassen Versicherung
  VGH: ['VGH', 'VGH Versicherung', 'Landschaftliche Brandkasse Hannover (VGH)'], // VGH
  Wuerttembergische: [
    'Württembergische',
    'Wuerttembergische',
    'Württembergische Versicherung',
    'Wuerttembergische Versicherung',
    'Württembergische Vers. AG'
  ], // Württembergische
  Zurich: ['Zurich', 'Zurich Versicherung', 'Zurich Insurance plc'] // Zurich
};

export {
  ADMINISTRATION_MENU_ITEMS,
  ALLOWED_FILE_TYPES,
  ATTACHMENT_MAX_SIZES,
  ATTACHMENT_TYPES,
  CAUSER_TYPES,
  CLAIM_DEFAULT_INSURED_RISK_TYPE,
  CLAIM_DETAILS_MENU_ITEMS,
  POLICY_DETAILS_MENU_ITEMS,
  BUILDING_DETAILS_MENU_ITEMS,
  CLAIM_LIABILITY_DAMAGE_TO_TYPES,
  CLAIMANT_RELATION_TO_POLICYHOLDER,
  CLAIM_STATUSES,
  CLAIMS_VIEW_OPTIONS,
  CLAIMS_SORT_OPTIONS,
  CLAIM_TYPE_FILTER_LABLE_COLORS,
  POLICIES_SORT_OPTIONS,
  INSURANCE_STATUSES,
  DAMAGED_UNIT_TYPES,
  DEFAULT_ROUTE_QUERY_VALUES,
  LIABILITY_INSURANCE_STATUSES,
  MENU_ITEMS,
  MAIN_NAVIGATION_HEIGHT,
  PAGE_HEADER_HEIGHT,
  PAGE_BOTTOM_MARGIN_HEIGHT,
  DETAILS_NAVIGATION_HEIGHT,
  DETAILS_TAB_MARGIN,
  BORDER_HEIGHT,
  ORGANISATIONS_TYPES,
  SETTINGS_MENU_ITEMS,
  SETTLEMENT_STATUSES,
  SETTLEMENT_TYPE_INVOICE,
  SETTLEMENT_TYPE_QUOTATION,
  FILTER_OPTIONS,
  DEFAULT_FILE_CONTAINER_IDS,
  DEFAULT_BUILDING_COORDINATES,
  BUILDING_EXTERNAL_ORIGIN_FIELDS,
  MAX_REMINDER_MESSAGE_LENGTH,
  NON_INSURED_COSTS,
  DIFFERENCE,
  NO_RISK_SELECTED_TITLE,
  INFORMATION_BANNER_TEXT,
  ERROR_TYPES,
  IMPORT_TABLE_ROW_STATUSES,
  FILTER_COUNT_SLIDER_MARKS,
  INSURANCE_ORGANIZATIONS_LOGOS,
  INSURANCE_ORGANIZATIONS,
  IMPORT_SUMMARY_ROWS
};
