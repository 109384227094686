import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppRouter from './containers/App/AppRouter';
import AuthRoute from './components/AuthRoute';
import Login from './containers/Login/Login';
import InitialSignIn from './containers/Login/InitialSignin';
import ResetPassword from './containers/Login/ResetPassword';
import SimpleLayout from './containers/Layout/SimpleLayout';
import { ERROR_TYPES } from './consts/consts';
import { ErrorPage } from './components/ErrorPage/ErrorPage';
import authActions from './redux/auth/actions';
import configActions from './redux/config/actions';

const PublicRoutes = ({ getConfig, validateUser, validateUserLoading, isLoggedIn }) => {
  // On initial load, validate the user token
  useEffect(() => {
    getConfig();
    validateUser();
  }, []);

  // If the user is not logged in, shut down Intercom
  useEffect(() => {
    if (!isLoggedIn && window.Intercom) {
      window.Intercom('shutdown');
    }
  }, [isLoggedIn]);

  if (validateUserLoading) {
    return React.Fragment;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/pageNotFound" element={<ErrorPage errorType={ERROR_TYPES.ERROR_PAGE_NOT_FOUND} />} />
        <Route path="/signin" element={<SimpleLayout component={Login} />} />
        <Route path="/initial-signin" element={<SimpleLayout component={InitialSignIn} />} />
        <Route path="/reset-password" element={<SimpleLayout component={ResetPassword} />} />
        <Route path="/*" element={<AuthRoute component={AppRouter} />} />
        <Route path="*" element={<ErrorPage errorType={ERROR_TYPES.ERROR_PAGE_NOT_FOUND} />} />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.get('isLoggedIn'),
  validateUserLoading: state.Auth.get('validateUserLoading')
});

const { validateUser } = authActions;
const { getConfig } = configActions;

const mapDispatchToProps = {
  getConfig,
  validateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoutes);
