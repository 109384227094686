import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Modal } from '../ui';
import AuthenticationLayout from '../../containers/Layout/AuthenticationLayout';
import usersActions from '../../redux/users/actions';

const OptInModalContent = styled.div`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
`;

const OptInModalContentHyperLink = styled.a`
  color: var(--primary-oqio-blue);
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;

  &:focus {
    outline: none;
  }
  :hover {
    color: var(--primary-oqio-blue);
  }
`;

const OptInModal = ({ config, user, confirmOptIn, confirmOptInLoading }) => {
  const handleConfirmOptIn = () => {
    if (confirmOptInLoading) {
      return;
    }
    confirmOptIn(user.id);
  };

  const footer = (
    <Button onClick={handleConfirmOptIn} type="primary">
      Zustimmen
    </Button>
  );

  return (
    <AuthenticationLayout hideLinks config={config}>
      <Modal
        closable={false}
        footer={footer}
        style={{ top: 250 }}
        mask={false}
        title="Nutzungsbedingungen & Datenschutzinformationen"
        width={700}
        open
        getContainer="no-pointer-events"
        createContainer
      >
        <OptInModalContent>
          Bitte stimmen Sie den aktualisierten&nbsp;
          <OptInModalContentHyperLink
            href={config && config.terms_of_use ? config.terms_of_use.url : '#'}
            target={config && config.terms_of_use ? '_blank' : ''}
            rel="noopener noreferrer"
          >
            Nutzungsbedingungen
          </OptInModalContentHyperLink>
          &nbsp;und&nbsp;
          <OptInModalContentHyperLink
            className="link"
            href={config && config.privacy_statement ? config.privacy_statement.url : '#'}
            target={config && config.privacy_statement ? '_blank' : ''}
            rel="noopener noreferrer"
          >
            Datenschutzinformationen
          </OptInModalContentHyperLink>
          &nbsp;zu.
        </OptInModalContent>
      </Modal>
    </AuthenticationLayout>
  );
};

const { confirmOptIn } = usersActions;

const mapDispatchToProps = {
  confirmOptIn
};

export default connect(null, mapDispatchToProps)(OptInModal);
