import AntInput from 'antd/es/input';
import styled from 'styled-components';

const AntInputPassword = AntInput.Password;

const InputPassword = styled(AntInputPassword)`
  &.ant-input-password {
    background-color: var(--greyscale-porcelain);
    background-image: none;
    border: ${(props) =>
      props.$hasErrors ? '1px solid var(--primary-oqio-red)' : '1px solid var(--greyscale-concrete)'};
    border-radius: var(--border-radius-small);
    color: var(--greyscale-blackish);
    cursor: text;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-regular);
    height: 40px;
    padding: 0px 10px;
    width: 100%;

    &.ant-input-status-error:not(.ant-input-disabled) {
      box-shadow: var(--box-shadow-oqio-red);

      &:hover {
        box-shadow: var(--box-shadow-oqio-blue);
      }
    }

    &:hover:not(.ant-input-disabled) {
      background-color: var(--greyscale-porcelain);
      border: 1px solid var(--primary-oqio-blue);
    }

    &.ant-input-affix-wrapper-focused {
      border: 1px solid var(--primary-oqio-blue);
      box-shadow: var(--box-shadow-oqio-blue);
    }

    input {
      background-color: var(--greyscale-porcelain);
      background-image: none;
      border: 0px;
      color: var(--greyscale-blackish);
      cursor: text;
      font-family: var(--font-family-regular);
      font-size: var(--font-size-regular);
      height: 36px;
      width: 100%;
      &:hover {
        background-color: var(--greyscale-porcelain);
        border: 0px !important;
      }
    }
  }

  &.ant-input-disabled {
    background-color: var(--greyscale-porcelain);
    border: 1px solid var(--greyscale-dark-white);
    color: var(--greyscale-concrete) !important;
    cursor: default;
    opacity: 0.8;

    &:hover {
      border: 1px solid var(--greyscale-dark-white);
    }
  }

  .ant-input-suffix {
    path {
      fill: var(--greyscale-blackish);
      stroke: var(--greyscale-blackish);
    }
  }
`;

export default InputPassword;
