import styled from 'styled-components';
import AntTable from 'antd/es/table';
import { windowMaxWidth } from '../Layout/breakpoints';

const Table = styled(AntTable)`
  &.ant-table-wrapper {
    position: relative;
    scrollbar-color: rgba(136, 136, 136, 0.3) transparent !important;

    .ant-table {
      background: var(--greyscale-white);
      color: var(--greyscale-blackish);
    }

    .ant-table-thead {
      th.ant-table-cell {
        background: var(--greyscale-white);
        border-bottom: 1px solid var(-greyscale-dark-white);
        color: var(--greyscale-shade);
        color: var(--greyscale-shade);
        flex: 0 1 auto;
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-medium);
        font-weight: var(--font-weight-medium);
        height: 40px;
        line-height: var(--font-line-height-regular);
        line-height: var(--font-line-height-medium);
        padding: 10px;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }

        .ant-table-column-sorters {
          justify-content: flex-start;

          .ant-table-column-title {
            flex: 0 1 auto;
          }
        }

        &:before {
          content: none !important;
        }
        &.ant-table-column-sort {
          color: var(--primary-oqio-blue);
          font-weight: var(--font-weight-semi-bold);
        }
      }

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        svg {
          fill: var(--greyscale-light-grey);
        }

        &.active {
          svg {
            fill: var(--primary-oqio-blue);
          }
        }
      }
    }

    .ant-table-tbody {
      tr.ant-table-row {
        cursor: pointer;

        &.active {
          background-color: var(--secondary-whisper);
          td.ant-table-column-sort {
            background-color: var(--secondary-whisper);
          }
        }
        .ant-table-cell-row-hover {
          background: var(--greyscale-porcelain);
        }
      }

      td.ant-table-cell {
        line-height: var(--font-line-height-regular);
        padding: 14px 10px;
        border-color: var(--greyscale-dark-white);

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }

        span:not(.ant-tag) {
          display: inline;
          line-height: var(--font-line-height-regular);
          max-width: 100%;
          overflow: hidden;
          padding: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        @media (min-height: 800px) {
          padding: 20px 10px;
        }
      }
    }

    &.table-within-modal {
      max-height: 300px;
      min-height: 300px;

      @media (${windowMaxWidth.mobile}) {
        min-height: 230px;
      }

      .ant-table-thead {
        th.ant-table-cell {
          &:first-child {
            padding-left: 40px;
          }

          &:last-child {
            padding-right: 40px;
          }
        }
      }

      .ant-table-tbody {
        td.ant-table-cell {
          padding: 2px 10px;

          &:first-child {
            padding-left: 40px;
          }

          &:last-child {
            padding-right: 40px;
          }
        }
      }
    }

    .ant-table {
      &.ant-table-empty {
        .ant-table-body {
          max-height: calc(100vh - 300px) !important;
        }
        .ant-table-content {
          overflow: hidden;
        }
      }
    }

    tr.ant-table-placeholder {
      background: var(--greyscale-white);

      .ant-table-cell {
        border-bottom: none;
      }
      &:hover {
        td {
          background: var(--greyscale-porcelain);
        }
      }
    }

    @media (${windowMaxWidth.mobile}) {
      margin-bottom: 70px !important;
    }
  }
`;

export default Table;
