import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';

const ExtendedAccordion = styled(Collapse)`
  &.ant-collapse {
    background-color: var(--greyscale-white);
    border: 0px;
    border-radius: var(--border-radius-small);
    color: var(--greyscale-blackish);
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-medium);

    &.low-profile {
      font-size: var(--font-size-medium);

      .ant-collapse-content-box {
        padding: 0;
      }
    }

    .ant-collapse-extra {
      display: flex;
    }
  }

  .ant-collapse {
    &-item {
      border-bottom: 1px solid var(--greyscale-dark-white);
      
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding-left: 40px;
          padding-right: 40px;    
        }
      }

      > .ant-collapse-header {
        align-items: center;
        color: var(--greyscale-blackish);

        svg path {
          stroke: var(--primary-oqio-red);

          &.dot {
            fill: var(--primary-oqio-red);
            stroke: transparent;
          }
        }
      }

      &-disabled {
        > .ant-collapse-header {
          color: var(--greyscale-concrete);
          cursor: default;

          svg path {
            stroke: var(--greyscale-concrete);

            &.dot {
              fill: var(--greyscale-concrete);
              stroke: transparent;
            }
          }
      }
    }
  }
`;

const Accordion = ({ expandIconPosition = 'start', ...props }) => (
  <ExtendedAccordion
    accordion={props.accordion || true}
    bordered={false}
    expandIconPosition={expandIconPosition}
    {...props}
  >
    {props.children}
  </ExtendedAccordion>
);

Accordion.Panel = ExtendedAccordion.Panel;

export default Accordion;
