import React from 'react';
import AntForm from 'antd/es/form';
import styled from 'styled-components';

const StyledForm = styled(AntForm)`
  .ant-form-item-label {
    align-items: flex-start;
    display: flex;
    height: 20px;
    justify-content: flex-start;

    label {
    }
  }

  .ant-form-item-explain {
    font-size: 12px;
    padding-left: 15px;
  }

  .ant-form-item {
    margin-bottom: 20px;

    &.mark-as-recommended {
      label {
        color: var(--primary-oqio-red);

        .ant-form-item-optional {
          color: var(--primary-oqio-red) !important;
        }
      }

      .ant-input {
        border: 1px solid var(--primary-oqio-red);
        box-shadow: var(--box-shadow-oqio-red);
      }
    }
  }

  .ant-form-item-optional {
    color: var(--greyscale-shade) !important;
  }
`;

const Form = ({ layout = 'vertical', requiredMark = false, label = '', ...props }) => {
  return <StyledForm layout={layout} requiredMark={requiredMark} label={label} {...props} />;
};

Form.Item = (props) => <AntForm.Item colon={false} {...props} validateFirst />;

// Expose static methods from AntForm
Form.useForm = AntForm.useForm;

export default Form;
