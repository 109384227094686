import styled from 'styled-components';
import { windowMaxWidth, windowMinWidth } from './breakpoints';

const SettlementsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 0 20px 20px;
`;

const SettlementsListItemWrapper = styled.div`
  align-items: center;
  background-color: var(--greyscale-porcelain);
  border-radius: 8px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 2px;
  min-height: 70px;
  padding: 10px 10px 10px 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${windowMaxWidth.mobile} {
    gap: 5px;
    padding: 10px;
  }
`;

const SettlementsListItemVerticalSectionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 150px;

  @media ${windowMaxWidth.mobile} {
    min-width: 0;
  }
`;

const SettlementsListItemHorizontalSectionWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: auto;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;

  &.attachments-modal-list-item {
    margin-left: 10px;
    padding: 0;
  }
`;

const SettlementsListStatusIconWrapper = styled.div`
  align-items: center;
  display: flex;

  svg {
    path {
      stroke: var(--greyscale-blackish) !important;
    }
    rect {
      fill: var(--greyscale-blackish);
    }
  }
`;

const SettlementsListDateWrapper = styled.span`
  align-items: center;
  color: var(--greyscale-shade);
  display: flex;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);

  @media ${windowMaxWidth.mobile} {
    display: none;
  }
`;

const SettlementsListDateWrapperMobile = styled.span`
  align-items: center;
  color: var(--greyscale-shade);
  display: none;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);

  @media ${windowMaxWidth.mobile} {
    display: flex;
  }
`;

const SettlementsListSmallSizeGreyTextWrapper = styled.span`
  align-items: center;
  color: var(--greyscale-shade);
  display: flex;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);

  &.attachments-modal-list-item {
    min-width: 225px;
  }
`;

const SettlementsListAmountLabel = styled.span`
  align-items: center;
  color: var(--greyscale-shade);
  display: flex;
  display: flex;
  font-size: var(--font-size-small);
  height: 20px;
  justify-content: flex-end;
  line-height: var(--font-line-height-small);
  min-width: 150px;

  @media ${windowMaxWidth.mobile} {
    min-width: 0;
  }
`;

const SettlementsListIdWrapper = styled.div`
  color: ${(props) => (props.$placeholder ? 'var(--greyscale-concrete)' : 'initial')};
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  justify-content: flex-start;
  line-height: var(--font-line-height-regular);
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SettlementsListNormalSizeBoldTextWrapper = styled.div`
  color: ${(props) => (props.$placeholder ? 'var(--greyscale-concrete)' : 'initial')};
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  justify-content: flex-end;
  line-height: var(--font-line-height-regular);
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SettlementsListAmountsWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.attachments-modal-list-item {
    min-width: unset;
  }
`;

const SettlementsListAmountsItemWrapper = styled.div`
  align-items: flex-end;
  column-gap: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media ${windowMaxWidth.splitScreen} {
    flex-direction: row;
  }

  @media ${windowMinWidth.laptopL} {
    flex-direction: row;
  }

  @media ${windowMaxWidth.mobile} {
    column-gap: 0;
  }
`;

const SettlementsListActionButtonWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  margin-left: 10px;

  span:not(.ant-btn-icon) {
    display: none;
  }

  @media ${windowMinWidth.laptopL} {
    margin-left: 20px;

    span:not(.ant-btn-icon) {
      display: flex;
    }
  }

  @media ${windowMaxWidth.mobile} {
    display: none;
  }
`;

const SettlementsListEmptyTextWrapper = styled.div`
  color: var(--greyscale-shade);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  padding: 0 10px;
`;

const SettlementsWorklistId = styled.div`
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  justify-content: flex-start;
  line-height: var(--font-line-height-regular);
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SettlementsListItemPanelContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const SettlementsListItemPanelDocument = styled.div`
  align-items: center;
  background-color: white;
  border-radius: var(--border-radius-regular);
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  padding: 5px 10px 5px 5px;
`;

const SettlementsListItemPanelDocumentTitle = styled.span`
  align-items: center;
  color: var(--greyscale-blackish);
  display: flex;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  gap: 5px;
  line-height: var(--font-line-height-regular);
`;

const SettlementsListLayout = {
  SettlementsListWrapper,
  SettlementsListItemWrapper,
  SettlementsListItemVerticalSectionWrapper,
  SettlementsListItemHorizontalSectionWrapper,
  SettlementsListStatusIconWrapper,
  SettlementsListDateWrapper,
  SettlementsListDateWrapperMobile,
  SettlementsListSmallSizeGreyTextWrapper,
  SettlementsListAmountLabel,
  SettlementsListIdWrapper,
  SettlementsListNormalSizeBoldTextWrapper,
  SettlementsListAmountsWrapper,
  SettlementsListAmountsItemWrapper,
  SettlementsListActionButtonWrapper,
  SettlementsListEmptyTextWrapper,
  SettlementsWorklistId,
  SettlementsListItemPanelContentWrapper,
  SettlementsListItemPanelDocument,
  SettlementsListItemPanelDocumentTitle
};

export default SettlementsListLayout;
