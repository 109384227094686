import styled from 'styled-components';
import AntRadio from 'antd/lib/radio';

const { Group } = AntRadio;

const StyledGroup = styled(Group)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: var(--font-family-regular);
  gap: 20px;

  .ant-radio-wrapper {
    display: flex;
    flex: 1 0 calc(50% - 10px);
    font-family: var(--font-family-regular);
    margin: 0;
    max-width: 274px;

    &:hover {
      .ant-radio-inner {
        border-color: var(--primary-oqio-blue-hover);
      }
    }
  }
`;

const RadioButton = styled(AntRadio)`
  &.ant-radio-wrapper {
    display: flex;
    flex: 1 0 calc(50% - 10px);
    font-family: var(--font-family-regular);
    margin: 0;
    max-width: 274px;

    &:hover {
      .ant-radio {
        .ant-radio-inner {
          border-color: var(--primary-oqio-blue-hover);
        }
      }
    }

    .ant-radio {
      align-self: flex-start;
      top: 1px;

      .ant-radio-inner {
        background-color: var(--greyscale-porcelain);
        border-color: var(--greyscale-concrete);
        height: 20px;
        width: 20px;
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: var(--primary-oqio-blue);
          border-width: 2px;

          &:after {
            background-color: var(--primary-oqio-blue);
            height: 20px;
            margin-block-start: -10px;
            margin-inline-start: -10px;
            transform: scale(0.5);
            width: 20px;
          }
        }
      }
    }

    &.ant-radio-wrapper-disabled {
      color: var(--greyscale-concrete);
      cursor: default;

      .ant-radio {
        .ant-radio-input {
          cursor: default;
        }

        .ant-radio-inner {
          background-color: var(--greyscale-dark-white);
          border-color: var(--greyscale-light-grey);
          cursor: default;
        }
      }
    }
  }
`;

RadioButton.Group = StyledGroup;

export default RadioButton;
