import { Drawer } from 'antd';
import styled from 'styled-components';
import { windowMaxWidth } from '../Layout/breakpoints';

const StyledDrawer = styled(Drawer)`
  font-family: var(--font-family-regular);

  &.header-menu-drawer {
    z-index: 1000;
  }

  &#sidebar-global {
    z-index: 999;
    height: calc(100vh - 60px);
    top: 60px;
    position: relative;
    box-shadow: var(--box-shadow-drawer);

    @supports (height: 100dvh) {
      height: calc(100dvh - 60px);
    }

    @media (${windowMaxWidth.mobile}) {
      top: 0;
      height: 100vh;

      @supports (height: 100dvh) {
        height: 100dvh;
      }
    }
  }

  .ant-drawer-content-wrapper {
    height: calc(100vh - 60px);
    top: 60px;
  }

  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
`;

export default StyledDrawer;
