import styled from 'styled-components';

export const ForgotPasswordStyleWrapper = styled.div`
  .email-input-field {
    margin-bottom: 0px;
    padding: 20px 20px 10px 20px;
  }
`;

export const ForgotPasswordButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
}`;
