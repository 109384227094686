import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import { Dropdown, Button } from '../ui';
import { DropdownWrapper, DropdownListItem, DropdownListItemDivider } from '../ui/Dropdown/Dropdown';
import TopbarUserChangePasswordModal from '../TopbarUserChangePasswordModal/TopbarUserChangePasswordModal';
import authActions from '../../redux/auth/actions';
import userActions from '../../redux/users/actions';
import { USER_ROLES_DICTIONARY } from '../../consts/dictionaries';
import { windowMaxWidth } from '../ui/Layout/breakpoints';
import { IconLogout, IconPassword } from '../ui/Icons';
import { findMetaDataDescriptionByProperty } from '../../helpers/utility';

const UserDetails = styled.div`
  color: var(--greyscale-blackish);
  padding: 20px;
`;

const UserPasswordWrapper = styled.div`
  button {
    display: flex;
    justify-content: flex-start;

    svg {
      path:last-child {
        fill: var(--primary-oqio-blue);
      }
    }
  }
`;

const UserLogoutWrapper = styled.div`
  button {
    display: flex;
    justify-content: flex-start;
  }
`;

const UserCompany = styled.div`
  -moz-user-select: auto;
  -ms-user-select: auto;
  -webkit-touch-callout: default;
  -webkit-user-select: auto;
  color: var(--greyscale-blackish);
  font-size: var(--font-size-small); /* iOS Safari */
  line-height: var(--font-line-height-medium); /* Internet Explorer/Edge */
  line-height: var(
    --font-line-height-medium
  ); /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  margin: 5px 0;
  max-width: 425px;
  overflow-wrap: break-spaces;
  user-select: text;
  white-space: pre-wrap;
`;

const UserWrapper = styled.div`
  align-items: baseline;
  display: flex;
`;

const UserName = styled.div`
  font-size: var(--font-size-heading-3);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-medium);
  margin-right: 6px;
`;

const UserRole = styled.div`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-medium);
`;

const Avatar = styled.div`
  background-color: var(--greyscale-blackish);
  border-radius: var(--border-radius-circle);
  color: var(--greyscale-white);
  cursor: pointer;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  height: 30px;
  line-height: var(--font-line-height-large);
  text-align: center;
  width: 30px;
`;

const TopbarUserMenuButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
  @media ${windowMaxWidth.splitScreen} {
    width: 30px;
  }
`;

/**
 * Functional component that renders the user menu in the top bar.
 * Includes the user avatar, dropdown menu with options to change password and logout,
 * and handles the display of the change password modal.
 * @property {Object} user - the current logged-in user information.
 * @property {Boolean} changeUserPasswordError - a boolean indicating if there was an error during password change.
 * @property {Boolean} changeUserPasswordLoading - a boolean indicating if the password change process is loading.
 * @property {Function} clearUserErrors - a function that clears user-related errors.
 * @property {Function} changeUserPassword - a function that triggers the password change process.
 * @property {Function} logout - a function that logs out the user.
 */
const TopbarUserMenu = ({
  changeUserPasswordError,
  clearUserErrors,
  changeUserPassword,
  changeUserPasswordLoading,
  logout,
  user
}) => {
  const { first_name, last_name, role } = user;

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [openTopbarUserMenu, setOpenTopbarUserMenu] = useState(false);

  const { shutdown } = useIntercom();

  // Toggle the change password modal visibility
  const handleToggleChangePasswordModal = () => {
    handleOpenChange(false);
    setShowChangePasswordModal(!changeUserPasswordError ? !showChangePasswordModal : false);
  };

  // Close the change password modal with error
  const handleCloseChangePasswordModalWithError = () => {
    clearUserErrors();
    handleToggleChangePasswordModal();
  };

  // Handle user logout
  const handleUserLogOut = () => {
    shutdown();
    logout();
    Sentry.setUser(null);
  };

  // Open/close the topbar user menu
  const handleOpenChange = (value) => setOpenTopbarUserMenu(value);

  const organizationName = findMetaDataDescriptionByProperty(user, 'organization_name', '', 'description');
  const isChangePasswordModalVisible = showChangePasswordModal || changeUserPasswordLoading || changeUserPasswordError;
  const fullName = `${first_name} ${last_name}`;
  const userRole = USER_ROLES_DICTIONARY[role] || role;
  const firstName = first_name.slice(0, 1).toUpperCase();
  const lastName = last_name.slice(0, 1).toUpperCase();

  // Render the dropdown menu content
  const dropdownRender = () => (
    <DropdownWrapper>
      <UserDetails>
        <UserCompany>{organizationName}</UserCompany>
        <UserWrapper>
          <UserName>{fullName}</UserName>
          <UserRole>{userRole}</UserRole>
        </UserWrapper>
      </UserDetails>
      <DropdownListItemDivider />
      <DropdownListItem>
        <UserPasswordWrapper>
          <Button type="text" onClick={handleToggleChangePasswordModal} icon={<IconPassword />} block>
            Passwort ändern
          </Button>
        </UserPasswordWrapper>
      </DropdownListItem>
      <DropdownListItemDivider />
      <DropdownListItem>
        <UserLogoutWrapper>
          <Button type="text" onClick={handleUserLogOut} icon={<IconLogout />} block>
            Logout
          </Button>
        </UserLogoutWrapper>
      </DropdownListItem>
    </DropdownWrapper>
  );

  return (
    <TopbarUserMenuButtonWrapper>
      <Dropdown onOpenChange={handleOpenChange} open={openTopbarUserMenu} dropdownRender={dropdownRender}>
        <Avatar>{`${firstName}${lastName}`}</Avatar>
      </Dropdown>
      {isChangePasswordModalVisible && (
        <TopbarUserChangePasswordModal
          changeUserPassword={changeUserPassword}
          onCancel={changeUserPasswordError ? handleCloseChangePasswordModalWithError : handleToggleChangePasswordModal}
          userId={user?.id}
          visible
          changeUserPasswordError={changeUserPasswordError}
        />
      )}
    </TopbarUserMenuButtonWrapper>
  );
};

const mapStateToProps = (state) => ({
  changeUserPasswordLoading: state.Users.get('changeUserPasswordLoading'),
  changeUserPasswordError: state.Users.get('changeUserPasswordError'),
  user: state.Auth.get('user')
});

const { logout } = authActions;
const { clearUserErrors, changeUserPassword } = userActions;

export default connect(mapStateToProps, { changeUserPassword, clearUserErrors, logout })(TopbarUserMenu);
