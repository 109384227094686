import AntSwitch from 'antd/es/switch';
import styled from 'styled-components';

const Switch = styled(AntSwitch)`
  &.ant-switch {
    background: var(--greyscale-concrete);
    height: 20px;
    min-width: 40px;

    &:hover {
      background: var(--greyscale-concrete);
    }

    &-checked {
      background-color: var(--primary-oqio-blue);

      &:hover {
        background: var(--primary-oqio-blue) !important;
      }

      .ant-switch-handle {
        inset-inline-start: calc(100% - 19px) !important;
      }
    }

    .ant-switch-handle {
      inset-inline-start: 1px;
      top: 1px;
      &::before {
        background-color: var(--greyscale-white);
        border: 1px solid var(--greyscale-dark-white);
        box-shadow: 0 0 4px 1px rgba(17, 17, 17, 0.1);
        height: 18px;
        width: 18px;
      }
    }

    .ant-wave {
      display: none;
    }
  }
`;

export default Switch;
