import styled from 'styled-components';
import AntCarousel from 'antd/es/carousel';

const Carousel = styled(AntCarousel)`
  background: var(--greyscale-white);
  height: inherit;

  &.details-page-carousel {
    .slick-list {
      height: inherit;

      .slick-track {
        height: inherit;

        .slick-slide {
          width: 530px;
          > div {
            align-items: center;
            display: flex;
            height: inherit;
            justify-content: center;
            width: inherit;
          }
        }
      }
    }
  }
`;

export default Carousel;
