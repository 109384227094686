import styled from 'styled-components';
import { Heading3 } from '../Heading/Heading';
import { windowMaxWidth } from './breakpoints';

const OrganizationsListWrapper = styled.div`
  height: calc(100vh - 253.8px);
  overflow: auto;
  padding-bottom: 20px;
  scrollbar-color: rgba(136, 136, 136, 0.3) transparent;
  scrollbar-width: thin;

  &.org-list-drawer {
    height: calc(100vh - 121px);
  }
`;

const ListHeaderWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid rgb(238, 238, 238);
  cursor: default;
  display: flex;
  font-size: var(--font-size-heading-3);
  font-weight: var(--font-weight-medium);
  justify-content: space-between;
  line-height: var(--font-line-height-medium);
  padding: 17px 20px;

  @media ${windowMaxWidth.splitScreen} {
    padding-left: 10px;
  }
`;

const ListHeaderLabelWrapper = styled.div`
  align-items: center;
  display: flex;

  svg {
    path {
      stroke: #111 !important;
    }
  }
`;

const ListHeaderLabelIcon = styled.div`
  height: 20px;
  margin-right: 10px;
`;

const ListHeaderLabelHeading = styled(Heading3)`
  margin: 0;
`;

const ListHeaderButtonWrapper = styled.div`
  display: flex;
`;

const ListItemWrapper = styled.div`
  align-items: center;
  overflow: hidden;
  padding: 10px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// Details Header
const OrganizationDetailsHeaderWrapper = styled.div`
  align-items: flex-start;
  background-color: var(--greyscale-white);
  border: solid 1px var(--greyscale-dark-white);
  border-radius: var(--border-radius-regular);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-regular);
  justify-content: space-between;
  margin-bottom: 20px;
`;

const OrganizationDetailsHeaderTopRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const OrganizationDetailsHeaderLabel = styled(Heading3)`
  align-items: center;
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  max-width: calc(100vw - 800px);
  min-width: 300px;

  @media ${windowMaxWidth.splitScreen} {
    max-width: calc(100vw - 128px);
    min-width: 200px;
  }
`;

const OrganizationDetailsHeaderMailLabel = styled(Heading3)`
  align-items: center;
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  max-width: calc(100vw - 800px);
  min-width: 300px;

  @media ${windowMaxWidth.splitScreen} {
    max-width: calc(100vw - 128px);
    min-width: 200px;
  }
`;

const OrganizationDetailsHeaderInfoWrapper = styled.div`
  align-items: center;
  color: var(--greyscale-concrete);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 20px;
  width: 100%;
`;

const OrganizationDetailsHeaderInfoITemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrganizationDetailsHeaderInfoItem = styled.div`
  align-items: flex-start;
  color: var(--greyscale-shade);
  display: flex;
  flex-direction: row;
  line-height: var(--font-line-height-regular);

  &:last-child {
    margin-top: 2px;
  }
`;

const OrganizationDetailsHeaderInfoItemIcon = styled.div`
  align-items: center;
  display: flex;
  margin-right: 10px;

  svg {
    circle {
      stroke: var(--greyscale-shade);
    }
    path {
      stroke: var(--greyscale-shade);
    }
  }
`;

// Details Content

const OrganizationDetailsContentWrapper = styled.div`
  background-color: var(--greyscale-white);
  border: solid 1px var(--greyscale-dark-white);
  border-radius: var(--border-radius-regular);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 346px);
  position: relative;
`;

const OrganizationDetailsNavigationWrapper = styled.div`
  border-bottom: 1px solid var(--greyscale-dark-white);
  display: flex;
  justify-content: space-between;
`;

const OrganizationDetailsNavigationTabsWrapper = styled.div`
  display: flex;
  padding: 10px 20px 0;
`;

const OrganizationDetailsNavigationAddButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-right: 10px;
`;

const OrganizationDetailsNavigationItem = styled.div`
  align-items: center;
  border-bottom: 2px solid ${(props) => (props.active ? 'var(--greyscale-blackish)' : 'transparent')};
  color: ${(props) => (props.active ? 'var(--greyscale-blackish)' : 'var(--greyscale-shade)')};
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: ${(props) => (props.active ? 'var(--font-weight-medium)' : 'var(--font-weight-regular)')};
  height: 40px;
  line-height: var(--font-line-height-regular);
  padding: 0 10px;
`;

const OrganizationDetailsTableWrapper = styled.div`
  border-radius: var(--border-radius-regular);
  overflow: hidden;
`;

const OrganizationDetailsTableWrapperOverlay = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--greyscale-white) 100%);
  border-radius: var(--border-radius-regular);
  bottom: 0;
  height: 50px;
  padding-bottom: 10px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 100;
`;

const OrganizationDetailsHeaderEditButton = styled.div`
  align-items: center;
  display: flex;
  height: 26px;
`;

// CreateEditModal Layout
const CreateEditOrganizationModalFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CreateEditOrganizationModalMessageSettingsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const CreateEditOrganizationModalMessageSettingsSectionLabel = styled.div`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-regular);
  font-weigth: normal;
  line-height: var(--font-line-height-regular);
  margin-bottom: 10px;
`;

const CreateEditOrganizationModalMessageSettingsSectionCheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-control-input {
      min-height: unset;
    }
  }
`;

const OrganizationsLayout = {
  OrganizationsListWrapper,
  ListHeaderWrapper,
  ListHeaderLabelWrapper,
  ListHeaderLabelIcon,
  ListHeaderLabelHeading,
  ListHeaderButtonWrapper,
  ListItemWrapper,
  OrganizationDetailsHeaderWrapper,
  OrganizationDetailsHeaderTopRowWrapper,
  OrganizationDetailsHeaderLabel,
  OrganizationDetailsHeaderMailLabel,
  OrganizationDetailsHeaderInfoWrapper,
  OrganizationDetailsHeaderInfoITemsWrapper,
  OrganizationDetailsHeaderInfoItem,
  OrganizationDetailsHeaderInfoItemIcon,
  OrganizationDetailsContentWrapper,
  OrganizationDetailsNavigationWrapper,
  OrganizationDetailsNavigationTabsWrapper,
  OrganizationDetailsNavigationAddButtonWrapper,
  OrganizationDetailsNavigationItem,
  OrganizationDetailsTableWrapper,
  OrganizationDetailsTableWrapperOverlay,
  OrganizationDetailsHeaderEditButton,
  CreateEditOrganizationModalFooterWrapper,
  CreateEditOrganizationModalMessageSettingsSectionWrapper,
  CreateEditOrganizationModalMessageSettingsSectionLabel,
  CreateEditOrganizationModalMessageSettingsSectionCheckboxesWrapper
};

export default OrganizationsLayout;
